interface FacilitySettingsType {
  useFacilityName?: boolean;
}
export interface FacilityType {
  id: number;
  name: string;
  identifier?: string;
  status: boolean;
  timezone: string;
  addressUnit?: string;
  addressStreet: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone?: string;
  email?: string;
  ccEmails: string[];
  bccEmails: string[];
  organizationId?: number;
  userIds: number[];
  logo?: string;
  settings: FacilitySettingsType;
}

export interface FacilityFormType {
  name: string;
  identifier?: string;
  status: boolean;
  timezone: string;
  addressUnit?: string;
  addressStreet: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone?: string;
  email?: string;
  organizationId?: number;
  ccEmails: string[];
  bccEmails: string[];
  userIds: number[];
  logo?: string;
  settings: FacilitySettingsType;
}

export const emptyFacility: FacilityType = {
  id: 0,
  name: '',
  identifier: undefined,
  status: true,
  timezone: 'America/Toronto',
  addressUnit: undefined,
  addressStreet: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  phone: undefined,
  email: undefined,
  ccEmails: [],
  bccEmails: [],
  organizationId: undefined,
  userIds: [],
  logo: undefined,
  settings: { useFacilityName: false }
};

export const facilityBinder = ({
  name,
  identifier,
  organizationId,
  timezone,
  phone,
  email,
  status,
  addressStreet,
  addressUnit,
  zipCode,
  city,
  state,
  country,
  ccEmails,
  bccEmails,
  userIds,
  logo,
  settings
}: FacilityType): FacilityFormType => ({
  name,
  identifier,
  organizationId,
  timezone,
  phone,
  email,
  status,
  addressStreet,
  addressUnit,
  zipCode,
  city,
  state,
  country,
  ccEmails,
  bccEmails,
  userIds,
  logo,
  settings
});

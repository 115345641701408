import { useState, useEffect } from 'react';
import { foldApi, REQUEST } from '../services/api/api';

type DependenciesType = any[] | undefined;

export function useFetchLoad<RES, REQ = any>(
  responsePromise: (...arg: any[]) => REQUEST<RES, REQ>,
  args: any[],
  dependencies: DependenciesType = [],
  triggers?: DependenciesType
): [
  RES | undefined,
  boolean,
  () => void,
  () => void,
  any,
  React.Dispatch<React.SetStateAction<RES | undefined>>
] {
  const [response, setResponse] = useState<RES>();
  const [header, setHeader] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const reload = () => {
    getData();
  };

  const reset = () => {
    setResponse(undefined);
  };

  const getData = () => {
    setLoading(true);
    const allDepsAvailable =
      dependencies &&
      dependencies.length > 0 &&
      dependencies.reduce(
        (availability, currentDep) => !!currentDep && availability,
        true
      );

    if (
      allDepsAvailable ||
      (Array.isArray(dependencies) && dependencies.length === 0)
    ) {
      foldApi(responsePromise(...args))
        .then(x => {
          setResponse(x.data);
          setHeader(x.headers);
        })
        .then(() => {
          setLoading(false);
        })
        .catch(err => console.error('OHOH', responsePromise, err));
    }
  };
  useEffect(
    () => {
      getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies ? dependencies.concat(triggers) : []
  );

  return [response, loading, reload, reset, header, setResponse];
}

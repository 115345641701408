import React, { useEffect, useState } from 'react';
import './Activate.scss';
import Outer from '../../OuterPages/Outer';
import { ReactComponent as LoadingIcon } from '../../../assets/svg/loading2.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/error.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/svg/success.svg';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/api/api';

type statusType =
  | 'loading'
  | 'successfull'
  | 'invalid'
  | 'alreadyVerifyed'
  | 'expired';

const Activate = () => {
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = useState<statusType>('loading');

  useEffect(() => {
    api.auth
      .verifyEmail(token)
      .then(res => {
        setStatus('successfull');
      })
      .catch(err => {
        setStatus('expired');
      });
  }, [token]);

  const history = useHistory();
  const data = {
    loading: {
      Icon: LoadingIcon,
      title: 'verifying your account …',
      button: null
    },
    successfull: {
      Icon: SuccessIcon,
      title: 'You’ve verified your account',
      button: {
        title: 'Login →',
        f: () => history.push('/login')
      }
    },
    invalid: {
      Icon: ErrorIcon,
      title: 'Sorry the token is invalid',
      button: {
        title: 'Signup →',
        f: () => history.push('/signup')
      }
    },
    alreadyVerifyed: {
      Icon: SuccessIcon,
      title: "you've already verifyed you account",
      button: {
        title: 'Login →',
        f: () => history.push('/login')
      }
    },
    expired: {
      Icon: ErrorIcon,
      title: 'your token is Expired.',
      button: {
        title: 'login →',
        f: () => history.push('/login')
      }
    }
  };
  const { Icon, title, button } = data[status];
  return (
    <Outer>
      <div className={`activate ${status}`}>
        <div className="activate__content">
          <Icon className={`activate__content__icon ${status}`} />
          <div className="activate__content__title">{title}</div>
        </div>
        {status !== 'loading' && (
          <div className="activate__button" onClick={button!.f}>
            {button!.title}
          </div>
        )}
      </div>
    </Outer>
  );
};

export default Activate;

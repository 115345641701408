import { Model } from 'survey-react';

const form = {
  title: {
    default: 'Self Declaration Form',
    es: 'Formulario de autodeclaración',
    fr: "Formulaire d'auto-déclaration"
  },
  description: {
    default:
      'For the health and safety of our community, declaration of illness is required. The following is a self-certification to help determine if you are clear to enter the facilities.',
    es:
      'Para la salud y seguridad de nuestra comunidad, se requiere declaraci�n de enfermedad. La siguiente es una autocertificaci�n para ayudar a determinar si est� autorizado para ingresar a las instalaciones.',
    fr:
      'Pour la santé et la sécurité de notre communauté, une déclaration de maladie est requise. Ce qui suit est une auto-certification pour aider à déterminer si vous êtes autorisé à entrer dans les installations.'
  },
  logoPosition: 'none',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'multipletext',
          name: 'name',
          title: {
            default: 'Name',
            es: 'Nombre',
            fr: 'Nom'
          },
          hideNumber: true,
          isRequired: true,
          items: [
            {
              name: 'firstname',
              isRequired: true,
              title: {
                es: 'primer nombre',
                default: 'First name',
                fr: 'Prénom'
              }
            },
            {
              name: 'lastname',
              isRequired: true,
              title: {
                es: 'apellido',
                default: 'Last name',
                fr: 'nom de famille'
              }
            }
          ]
        },
        {
          type: 'text',
          name: 'email',
          title: {
            default: 'Email (optional)',
            es: 'Email (opcional)',
            fr: 'Email (facultatif)'
          },
          inputType: 'email'
        },
        {
          type: 'radiogroup',
          name: 'type',
          title: {
            default: 'Reason for declaration',
            es: 'Razón de la declaración',
            fr: 'Motif de la déclaration'
          },
          isRequired: true,
          choices: [
            {
              value: 'patient',
              text: {
                default: 'patient',
                es: 'paciente'
              }
            },
            {
              value: 'visitor',
              text: {
                default: 'visitor',
                es: 'visitante',
                fr: 'visiteur'
              }
            },
            {
              value: 'employee',
              text: {
                es: 'empleado',
                default: 'employee',
                fr: 'employé'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'radiogroup',
          name: 'longtermcare',
          title:
            'Do you have a concern for a potential COVID-19 infection for the person? (this question is only to be asked to Long-Term Care or Retirement Home staff by Dispatch Centres.',
          choices: [
            {
              value: 'true',
              text: 'Yes'
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        },
        {
          type: 'radiogroup',
          name: 'travel',
          title:
            'Do you have close contact with anyone with acute respiratory illness or travelled outside of Canada in the past 14 days?',
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: 'Yes'
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        },
        {
          type: 'radiogroup',
          name: 'confirmedcase',
          title:
            'Do you have a confirmed case of COVID-19 or had close contact with a confirmed case of COVID-19?',
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: 'Yes'
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        },
        {
          type: 'matrix',
          name: 'symptoms',
          title: {
            default: 'Do you have any of the following symptoms?',
            es: '¿Tiene alguno de los siguientes síntomas?',
            fr: "Avez-vous l'un des symptômes suivants?"
          },
          isRequired: true,
          columns: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'si',
                fr: 'Oui'
              }
            },
            {
              value: 'false',
              text: {
                default: 'No',
                fr: 'Non'
              }
            }
          ],
          rows: [
            {
              value: 'Fever',
              text: {
                es: 'Fiebre (temperatura superior a 100,0 F)',
                default: 'Fever (temperature greater than 100.0 F)',
                fr: 'Fièvre (température supérieure à 100,0 F)'
              }
            },
            {
              value: 'cough',
              text: {
                en: 'Cough',
                es: 'Nueva aparición de tos',
                default: 'New onset of cough',
                fr: 'Nouvelle apparition de toux'
              }
            },
            {
              value: 'chronic',
              text: {
                es: 'Empeoramiento de la tos crónica',
                en: 'Shortness of Breath',
                default: 'Worsening chronic cough',
                fr: 'Aggravation de la toux chronique'
              }
            },
            {
              value: 'shortness',
              text: {
                en: 'Persistent Pain in the Chest',
                es: 'Dificultad para respirar',
                default: 'Shortness of breath',
                fr: 'Essoufflement'
              }
            },
            {
              value: 'breathing',
              text: {
                es: 'Respiración dificultosa',
                en: 'Blue lips or face',
                default: 'Difficulty breathing',
                fr: 'Difficulté à respirer'
              }
            },
            {
              value: 'sore',
              text: {
                es: 'Dolor de garganta',
                en: 'Severe dizziness or feel lightheaded',
                default: 'Sore throat',
                fr: 'Gorge irritée'
              }
            },
            {
              value: 'sallow',
              text: {
                es: 'Dificultad para tragar',
                en: 'Inability to lie down because of difficulty breathing',
                default: 'Difficulty swallowing',
                fr: 'Difficulté à avaler'
              }
            },
            {
              value: 'taste',
              text: {
                default: 'Decrease or loss of sense of taste or smell',
                es: 'Disminución o pérdida del sentido del gusto u olfato.',
                fr: "Diminution ou perte du sens du goût ou de l'odorat"
              }
            },
            {
              value: 'chills',
              text: {
                default: 'Chills',
                es: 'Resfriado',
                fr: 'Des frissons'
              }
            },
            {
              value: 'headache',
              text: {
                default: 'Headache',
                es: 'Dolor de cabeza',
                fr: 'Mal de crâne'
              }
            },
            {
              value: 'fatigue',
              text: {
                default: 'Unexplained fatigue/malaise/muscle aches (myalgias)',
                es:
                  'Fatiga / malestar / dolores musculares inexplicables (mialgias)',
                fr: 'Fatigue / malaise / courbatures inexpliqués (myalgies)'
              }
            },
            {
              value: 'nausea',
              text: {
                default: 'Nausea/vomiting, diarrhea, abdominal pain',
                es: 'Náuseas / vómitos, diarrea, dolor abdominal.',
                fr: 'Nausées / vomissements, diarrhée, douleurs abdominales'
              }
            },
            {
              value: 'pink',
              text: {
                default: 'Pink eye (conjunctivitis)',
                es: 'Ojos rosados ??(conjuntivitis)',
                fr: 'Œil rose (conjonctivite)'
              }
            },
            {
              value: 'nose',
              text: {
                default:
                  'Runny nose/nasal congestion without other known cause',
                es: 'Goteo nasal / congestión nasal sin otra causa conocida',
                fr: 'Nez qui coule / congestion nasale sans autre cause connue'
              }
            }
          ],
          isAllRowRequired: true
        },
        {
          type: 'text',
          name: '70year',
          title: {
            default:
              'If you are 70 years of age or older, and experiencing any of the following symptoms: delirium, unexplained or increased number of falls, acute functional decline, or worsening of chronic conditions?',
            es:
              'Si tiene 70 años o más y experimenta alguno de los siguientes síntomas: delirio, número inexplicable o aumentado de caídas, deterioro funcional agudo o empeoramiento de condiciones crónicas.',
            fr:
              "Si vous avez 70 ans ou plus et que vous présentez l'un des symptômes suivants: délire, nombre inexpliqué ou accru de chutes, déclin fonctionnel aigu ou aggravation de problèmes de santé chroniques?"
          }
        }
      ]
    }
  ],
  showPageTitles: false,
  showCompletedPage: false,
  showQuestionNumbers: 'off',
  showProgressBar: 'top',
  startSurveyText: {
    default: 'Start',
    es: 'comienzo',
    fr: 'Début'
  },
  pagePrevText: {
    default: 'Back',
    es: 'atrás',
    fr: 'Retour'
  },
  pageNextText: {
    default: 'Next',
    es: 'próximo',
    fr: 'Prochain'
  },
  completeText: {
    default: 'Submit',
    es: 'Enviar',
    fr: 'Soumettre'
  },
  previewText: {
    default: 'Preview',
    es: 'Avance',
    fr: 'Aperçu'
  },
  showPreviewBeforeComplete: 'showAllQuestions'
};

const suveyModel = new Model(form);
suveyModel.setValue('name', {
  firstname: localStorage.firstname,
  lastname: localStorage.lastname
});
suveyModel.setValue('email', localStorage.email);
suveyModel.setValue('type', localStorage.assessmentType);

export default suveyModel;

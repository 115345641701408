import { pipe } from 'ramda';
import { REQUEST } from './api';
// import { REQUEST } from "./api.base";
// import * as t from "io-ts";
// import qs, { stringify } from "query-string";
export type sortOrderType = 'ascend' | 'descend';

const appendQ = (key: string, value?: string) => <RES, REQ>(
  apireq: REQUEST<RES, REQ>
) => {
  if (value) {
    apireq.queries = { ...apireq.queries, ...{ [key]: value } };
  } else {
    delete apireq.queries[key];
  }

  return apireq;
};

const search = (s?: string) => appendQ('s', s);

const sort = (orderBy?: string, orderDir?: sortOrderType) =>
  pipe(
    appendQ('sortBy', orderBy),
    appendQ(
      'order',
      orderDir === 'ascend'
        ? 'ASC'
        : orderDir === 'descend'
        ? 'DESC'
        : undefined
    )
  );

const pagination = (page: number, limit: number) =>
  pipe(appendQ('page', page.toString()), appendQ('limit', limit.toString()));

const arrayFilter = (name: string, ...filters: any[]) =>
  pipe(appendQ(name, JSON.stringify(filters)));

const singleFilter = (name: string, filter: string) =>
  pipe(appendQ(name, filter));

const empty = () => pipe(appendQ(''));

const apifns = {
  search,
  sort,
  pagination,
  arrayFilter,
  singleFilter,
  empty
};

export default apifns;

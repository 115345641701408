import { makeRequest, methods } from '../api';
import { ServiceRequsetType } from './serviceReport.model';

const url = '/reports';

const getAll = (...touchpoints: number[]) => {
  const params =
    touchpoints.length > 0 ? `?touchpointIds=[${touchpoints}]` : '';
  return makeRequest<ServiceRequsetType[]>(url + params, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<ServiceRequsetType>(`${url}/${id}`, methods.get);
};

const sendComment = (id: number, comment: string) => {
  return makeRequest<ServiceRequsetType>(
    `${url}/addComment/${id}`,
    methods.put,
    {
      comment
    }
  );
};

const statistic = () => {
  return makeRequest<{ active: number }>(`${url}/other/statistic`, methods.get);
};

const monthlyStatistic = () => {
  return makeRequest<any>(`${url}/other/monthly-statistic`, methods.get);
};

const dailyStatistic = () => {
  return makeRequest<any>(`${url}/other/daily-statistic`, methods.get);
};

const serviceReportApi = {
  getAll,
  getOne,
  sendComment,
  statistic,
  monthlyStatistic,
  dailyStatistic
};

export default serviceReportApi;

import { HighlightTwoTone, CalendarTwoTone } from '@ant-design/icons';
import { Row, Col, Timeline, Empty } from 'antd';
import { Input, Form } from 'formik-antd';
import { Formik, FormikHelpers } from 'formik';
import { SubmitButton } from 'formik-antd';
import React from 'react';
import Dater from '../Dater/Dater';

interface CommentSubmit {
  comment: string;
}
interface CommentsProps {
  data: any;
  onSubmit: (
    values: CommentSubmit,
    formikHelpers: FormikHelpers<CommentSubmit>
  ) => void;
}

const Comments = ({ data, onSubmit }: CommentsProps) => {
  return (
    <Row>
      <Col md={18}>
        <div
          style={{
            padding: '20px',
            maxHeight: '200px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            direction: 'rtl'
          }}
        >
          <div
            style={{
              direction: 'ltr'
            }}
          >
            <Timeline reverse>
              {data.comments.length > 0 ? (
                data.comments.map((c: any, index: any) => (
                  <Timeline.Item key={index}>
                    <p
                      style={{
                        fontSize: '1.1em'
                      }}
                    >{`${c.comment}`}</p>
                    <div style={{ fontSize: '0.9em', fontWeight: 500 }}>
                      <p>
                        <HighlightTwoTone style={{ paddingRight: '10px' }} />
                        {`${c.user.firstName} ${c.user.lastName}`}
                      </p>
                      <p>
                        <CalendarTwoTone style={{ paddingRight: '10px' }} />
                        <Dater date={c.date} />
                      </p>
                    </div>
                  </Timeline.Item>
                ))
              ) : (
                <Empty description="No Comment" />
              )}
            </Timeline>
          </div>
        </div>
      </Col>
      <Col md={6}>
        <Formik initialValues={{ comment: '' }} onSubmit={onSubmit}>
          <Form>
            <Form.Item name="comment">
              <Input.TextArea
                name="comment"
                placeholder="Add a comment..."
                rows={4}
              />
            </Form.Item>
            <Form.Item name="submit">
              <SubmitButton htmlType="submit" type="primary">
                Save
              </SubmitButton>
            </Form.Item>
          </Form>
        </Formik>
      </Col>
    </Row>
  );
};

export default Comments;

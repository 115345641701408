import { Bar } from '@ant-design/charts';
import { Card } from 'antd';
import { pipe } from 'fp-ts/lib/function';
import React, { FC } from 'react';
import api from '../../../services/api/api';
import apifns from '../../../services/api/api.functions';
import { useFetchLoad } from '../../../utils/useFetchLoad';

interface SummaryProps {
  year: number;
  organizations: number[];
  facilities: number[];
}

const Summary: FC<SummaryProps> = ({ year, organizations, facilities }) => {
  const [assessment, assessmentLoading] = useFetchLoad(
    () =>
      pipe(
        api.assessment.statistic(),
        apifns.singleFilter('year', year.toString()),
        organizations.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizations),
        facilities.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('facilityIds', ...facilities)
      ),
    [year, organizations, facilities],
    [year, organizations, facilities]
  );
  const [serviceRequest, serviceRequestLoading] = useFetchLoad(
    () =>
      pipe(
        api.serviceRequest.statistic(),
        apifns.singleFilter('year', year.toString()),
        organizations.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizations),
        facilities.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('facilityIds', ...facilities)
      ),
    [year, organizations, facilities],
    [year, organizations, facilities]
  );
  const [formResult, formResultLoading] = useFetchLoad(
    () =>
      pipe(
        api.formResults.statistic(),
        apifns.singleFilter('year', year.toString()),
        organizations.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizations),
        facilities.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('facilityIds', ...facilities)
      ),
    [year, organizations, facilities],
    [year, organizations, facilities]
  );

  return (
    <Card title="Summary">
      {
        <Bar
          data={[
            {
              label: 'Count',
              type: 'Assessments',
              value: assessment?.active
            },
            {
              label: 'Count',
              type: 'Form Results',
              value: formResult?.active
            },
            {
              label: 'Count',
              type: 'Service Request',
              value: serviceRequest?.active
            }
          ]}
          loading={
            assessmentLoading || formResultLoading || serviceRequestLoading
          }
          height={150}
          legend={{
            position: 'right-top',
            padding: [5, 0, 25, 0]
          }}
          xField="value"
          yField="label"
          isGroup
          seriesField="type"
          color={['#61DDAA', '#65789B', '#5B8FF9']}
          label={{
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' }
            ]
          }}
        />
      }
    </Card>
  );
};

export default Summary;

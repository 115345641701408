import { CompareFn } from 'antd/lib/table/interface';
import * as yup from 'yup';
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneValidation = yup
  .string()
  .min(10)
  .max(10)
  .matches(/^\d+$/, 'phone must be exactly 10 digits.');

export const zipcodeValidation = yup.string().min(5).max(6).required();

export const passwordValidation = yup
  .string()
  .min(8, 'password must be more than 8 caracters')
  .matches(RegExp(`(?![.\n])`), "a password can't contain white space. ")
  .matches(RegExp(`(?=.*[a-z]).*$`), 'password must have lowercase character.')
  .matches(RegExp(`(?=.*[A-Z]).*$`), 'password must have uppercase character.')
  .matches(RegExp(`(?=.*\\d)`), 'password must have number.')
  .required();

const tempRanges = [
  {
    from: 34,
    to: 43
  },
  {
    from: 94,
    to: 108
  }
];

const checkTempRange = (t: number | undefined) =>
  t === undefined
    ? true
    : tempRanges.reduce(
        (acc, curr) => acc || (t >= curr.from && t <= curr.to),
        false
      );

export const tempFormSchema = yup.object().shape({
  bodyTemp: yup
    .number()
    .test('bodyTemp', 'not in range', t => checkTempRange(t))
});

export const reTypePasswordValidation = yup
  .string()
  .oneOf([yup.ref('password'), undefined], "it's not match with password.")
  .required('Password confirm is required');

export const compareString: CompareFn<string | undefined> = (a, b) =>
  a && b ? (a.toLowerCase() > b.toLowerCase() ? -1 : 1) : a ? -1 : 1;
export const compareStringg = <A>(key: keyof A): CompareFn<any> => (a, b) =>
  compareString(a[key], b[key]);

export const ObjMap = <T, R>(
  obj: Record<string, T>,
  f: (a: T) => R
): Record<string, R> =>
  Object.keys(obj).reduce((state: Record<string, R>, key) => {
    state[key] = f(obj[key]);
    return state;
  }, {});

export const toBoolean = (x: boolean) => (x ? 'true' : 'false');
export const objToBoolean = (obj: Record<string, boolean>) =>
  ObjMap(obj, toBoolean);

export const assessmentFix = (data: any) => {
  data.symptoms = objToBoolean(data.symptoms);
  if (data.symptoms2) {
    data.symptoms2 = objToBoolean(data.symptoms2);
  }
  if (data.confirmedcase) {
    data.confirmedcase = toBoolean(data.confirmedcase);
  }
  if (data.travel) {
    data.travel = toBoolean(data.travel);
  }
  if (data.longtermcare) {
    data.longtermcare = toBoolean(data.longtermcare);
  }
  return data;
};

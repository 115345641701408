import { makeRequest, methods } from '../api';
import { FormFormType, FormType } from './form.type';

const url = '/forms';

const getAll = (...organizations: number[]) => {
  const params =
    organizations.length > 0 ? `?organizationIds=[${organizations}]` : '';
  return makeRequest<FormType[]>(url + params, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<FormType>(`${url}/${id}`, methods.get);
};

const creatOne = (data: FormFormType) => {
  return makeRequest(`${url}`, methods.post, data);
};

const updateOne = (id: string, data: Partial<FormFormType>) => {
  return makeRequest(`${url}/${id}`, methods.put, data);
};

const getWithToken = (token: string) => {
  return makeRequest<FormType>(`${url}/token/${token}`, methods.get);
};

const formApi = {
  getAll,
  getOne,
  creatOne,
  updateOne,
  getWithToken
};

export default formApi;

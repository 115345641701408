import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import './Timer.scss';

interface TimerPorps {
  className?: string;
  onEnd?: () => void;
}
const useTimer = (
  period: number,
  on?: boolean
): [FC<TimerPorps>, boolean, () => void, () => void] => {
  const [time, setTime] = useState(period);
  const [started, setStarted] = useState(on);
  const [done, setDone] = useState(!on);

  const parseTime = (t: number) => {
    const minute = '00' + Math.floor(t / 60).toString();
    const second = '00' + Math.floor(t % 60).toString();
    return `${minute.slice(-2, undefined)}:${second.slice(-2, undefined)}`;
  };

  const reset = () => {
    setTime(period);
    setDone(false);
    start();
  };
  const start = () => setStarted(true);

  useEffect(() => {
    let interval: any;
    if (started && time !== 0) {
      interval = setInterval(() => {
        setTime(x => x - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval);
      setStarted(false);
      setDone(true);
    }
    return () => clearInterval(interval);
  }, [time, started]);

  const Timer: FC<TimerPorps> = ({ className, onEnd }) => {
    return (
      <div className={`timer ${className}`}>
        <Clock className="timer__icon" />
        <div className="timer__time">{parseTime(time)}</div>
      </div>
    );
  };

  return [Timer, done, start, reset];
};
export default useTimer;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Table, Card, Badge, Pagination } from 'antd';
import api, { foldApi } from '../../../services/api/api';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import { CommentOutlined } from '@ant-design/icons';
import Comments from '../../../components/Comments/comments.component';
import Dater from '../../../components/Dater/Dater';
import { useList } from '../../../hooks/useList';

const { Column } = Table;

export function ReportsList(
  props: RouteComponentProps & { match: { params: { touchId: string } } }
) {
  const loc = useLocation();
  const history = useHistory();
  const [touchId, setTouchId] = useState<any>();

  useEffect(() => {
    const x = new URLSearchParams(loc.search).get('touchId');
    setTouchId(x);
  }, [loc]);

  const {
    data,
    loading,
    paginationConfig,

    onTableChange,
    setListData
  } = useList(api.serviceRequest.getAll);

  const [touchpoint] = useFetchLoad(
    api.touchpoint.getOne,
    [touchId],
    [touchId]
  );

  return (
    <Card
      className="items-list-table"
      title={
        <>
          {'Service Request Reports'}
          {touchId && (
            <span>
              <span>({touchpoint && touchpoint.name})</span>
              <span
                onClick={() => {
                  history.push('/reports/serviceRequest');
                  setTouchId(undefined);
                }}
                style={{
                  color: '#1890ff',
                  fontSize: '0.7em',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
              >
                clear
              </span>
            </span>
          )}
        </>
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={false}
        onChange={onTableChange}
        loading={loading}
        expandable={{
          expandIconColumnIndex: 5,
          expandIcon: ({ expanded, onExpand, record }) =>
            record.comments.length > 0 ? (
              <Badge dot>
                <CommentOutlined onClick={e => onExpand(record, e)} />
              </Badge>
            ) : (
              <CommentOutlined onClick={e => onExpand(record, e)} />
            ),
          expandedRowRender: record => (
            <Comments
              data={record}
              onSubmit={(values: any, { resetForm }: any) => {
                return foldApi(
                  api.serviceRequest.sendComment(record.id, values.comment)
                )
                  .then(newData => {
                    if (data)
                      setListData(
                        data.map(oldData =>
                          oldData.id === newData.data.id
                            ? { ...oldData, comments: newData.data.comments }
                            : oldData
                        )
                      );
                    resetForm({});
                    return true;
                  })
                  .catch(console.error);
              }}
            />
          )
        }}
      >
        <Column
          title="Reporter Name"
          dataIndex="name"
          width="10%"
          key="name"
          sorter
        />
        <Column title="Email" dataIndex="email" width="10%" />
        <Column title="Comment" dataIndex="comment" key="comment" sorter />
        <Column
          title="Facility"
          dataIndex={['touchpoint', 'facility', 'name']}
          width="10%"
          key="faility"
          sorter
        />
        <Column
          title="Touchpoint"
          dataIndex={['touchpoint', 'name']}
          width="10%"
          key="touchpoint"
          sorter
        />
        <Column
          title="Date"
          render={(record: any) => <Dater date={record.createdAt} />}
          width="10%"
          key="createdAt"
          sorter
        />
      </Table>
      <Pagination
        style={{
          padding: '20px'
        }}
        {...paginationConfig}
      />
    </Card>
  );
}

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout, Button, Row, Col, Dropdown, Card } from 'antd';
import { SideMenu } from './SideMenu';
import { removeToken, removeData, getUsername } from '../../../services/auth';

const { Header, Content, Footer } = Layout;

export const BaseAdmin = withRouter(
  (props: { selectedMenu: string[]; children: any } & RouteComponentProps) => {
    const { selectedMenu, children, history } = props;
    const onLogout = () => {
      removeToken();
      removeData();
      history.push('/login');
    };

    return (
      <Layout>
        <SideMenu selectedKeys={selectedMenu} />
        <Layout>
          <Header style={{ backgroundColor: '#fff' }}>
            <Row justify="space-between">
              <Col span={20}>{/* <h2>Scan | Tap</h2> */}</Col>
              <Col span={1}>
                <Dropdown
                  overlay={
                    <div>
                      <Card
                        style={{ width: 200 }}
                        actions={[
                          <SettingOutlined key="setting" />,
                          <PoweroffOutlined key="poweroff" onClick={onLogout} />
                        ]}
                      >
                        {getUsername()}
                      </Card>
                    </div>
                  }
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <Button shape="round" type="primary">
                    <UserOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: '24px 20px' }}>{children}</Content>
          <Footer style={{ textAlign: 'center' }}>
            Scan|Tap - Copyright © {new Date().getFullYear()} Press'nXPress All
            Rights Reserved |
            <a href="https://pressnxpress.com/privacy-policy/">
              {' Privacy Policy'}
            </a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
);

import { userRoleType } from './api/User/user.model';

const tokenKey = 'userAccessToken';
const usernameKey = 'userUsername';
const roleKey = 'userRole';

export const hasToken = () => !!localStorage.getItem(tokenKey);
export const removeToken = () => localStorage.removeItem(tokenKey);
export const setToken = (access: string) =>
  localStorage.setItem(tokenKey, access);
export const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem(tokenKey)}`
});
export const setData = (username: string, role: string) => {
  localStorage.setItem(usernameKey, username);
  localStorage.setItem(roleKey, role);
};
export const removeData = () => {
  localStorage.removeItem(usernameKey);
  localStorage.removeItem(roleKey);
};
export const getUsername = () => localStorage.getItem(usernameKey);
export const getRole = () => localStorage.getItem(roleKey);
export const hasRole = (...roles: string[]) =>
  roles.includes(localStorage.getItem(roleKey) || ' ');

export const roleCheck = (...userRoles: userRoleType[]) =>
  userRoles.reduce((aqu, cur) => aqu || getRole() === cur, false);

import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { CommentOutlined, EyeFilled, PrinterFilled } from '@ant-design/icons';
import { Table, Card, Modal, Row, Col, Badge, Pagination } from 'antd';

import { roleCheck } from '../../../services/auth';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import { FormResultType } from '../../../services/api/FormResults/formResults.type';
import SurveyResult from '../../../components/SurveyResult';
import Comments from '../../../components/Comments/comments.component';
import Dater from '../../../components/Dater/Dater';
import { useList } from '../../../hooks/useList';
import useLock from '../../../hooks/useLock';
import { pipe } from 'fp-ts/lib/function';
import apifns from '../../../services/api/api.functions';
import Search from 'antd/lib/input/Search';

const { Column } = Table;

const FormResultsList = (
  props: RouteComponentProps & { match: { params: { orgId: string } } }
) => {
  const loc = useLocation();
  const [formId, setFormId] = useState<undefined | string | null>();
  const [search, setSearch] = useState<string | undefined>();

  const lock = useLock(() => {
    const x = new URLSearchParams(loc.search).get('formId');
    if (x === null || x === undefined) {
      setFormId(undefined);
    } else {
      setFormId(x);
    }
  }, []);

  const {
    data,
    loading,
    paginationConfig,
    setListData,
    onTableChange
  } = useList(
    () =>
      pipe(
        api.formResults.getAll(),
        apifns.arrayFilter('formTokens', formId),
        apifns.search(search)
      ),
    [lock],
    [formId, search]
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentResult, setCurrentResult] = useState<FormResultType>();

  const [orgsData, orgsLoading] = useFetchLoad(
    api.organization.getAll,
    [],
    [roleCheck('admin')]
  );
  const [formsData, formsLoading] = useFetchLoad(api.form.getAll, [], []);

  const getFormTitle = useCallback(
    (token: string) => formsData?.find(l => l.token === token)?.title,
    [formsData]
  );

  const getOrgName = useCallback(
    (orgId: number) => orgsData?.find(x => x.id === orgId)?.name,
    [orgsData]
  );

  const expandIconF = useCallback(
    ({ expanded, onExpand, record }) =>
      record.comments.length > 0 ? (
        <Badge dot>
          <CommentOutlined onClick={e => onExpand(record, e)} />
        </Badge>
      ) : (
        <CommentOutlined onClick={e => onExpand(record, e)} />
      ),
    []
  );

  const expandedRowRenderF = useCallback(
    record => (
      <Comments
        data={record}
        onSubmit={(values, { resetForm }) => {
          return foldApi(api.formResults.sendComment(record.id, values.comment))
            .then(newData => {
              if (data)
                setListData(
                  data.map(oldData =>
                    oldData.id === newData.data.id
                      ? { ...oldData, comments: newData.data.comments }
                      : oldData
                  )
                );
              resetForm({});
              return true;
            })
            .catch(console.error);
        }}
      />
    ),
    [data, setListData]
  );

  return (
    <>
      {currentResult ? (
        <Modal
          title={
            <div>
              <span>Form Result</span>

              <a
                href={`/survey/results/${currentResult.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrinterFilled style={{ marginLeft: '10px' }} />
              </a>
            </div>
          }
          visible={openModal}
          onCancel={() => {
            setOpenModal(false);
          }}
          footer={null}
        >
          <Row gutter={32}>
            <Col md={24}>
              <SurveyResult pResult={currentResult} />
            </Col>
          </Row>
        </Modal>
      ) : (
        ''
      )}
      <Card
        className="items-list-table"
        title="Form Results"
        extra={
          <Search onSearch={setSearch} placeholder="Search name or email" />
        }
      >
        <Table
          rowKey={(record: any) => record.id}
          dataSource={data}
          pagination={false}
          onChange={onTableChange}
          loading={loading}
          expandable={{
            expandIconColumnIndex: 8,
            expandIcon: expandIconF,
            expandedRowRender: expandedRowRenderF
          }}
        >
          <Column
            title="Form"
            sorter
            key="form"
            render={(record: FormResultType) => {
              return formsLoading || !formsData
                ? '-'
                : getFormTitle(record.formToken);
            }}
          />
          <Column
            title="Firstname"
            sorter
            key="firstname"
            dataIndex={['result', 'firstname']}
          />
          <Column
            title="Lastname"
            sorter
            key="lastname"
            dataIndex={['result', 'lastname']}
          />
          <Column
            title="Email"
            dataIndex={['result', 'email']}
            sorter
            key="email"
          />
          {roleCheck('admin') && (
            <Column
              title="Organization"
              render={record => {
                return orgsLoading || !orgsData || !record.touchpoint
                  ? '-'
                  : getOrgName(record.touchpoint.facility.organizationId);
              }}
            />
          )}
          <Column
            title="Facility"
            dataIndex={['touchpoint', 'facility', 'name']}
          />
          <Column
            title="Touchpoint"
            key="touchpoint"
            sorter
            dataIndex={['touchpoint', 'name']}
          />

          <Column
            title="Date"
            sorter
            key="createdAt"
            render={record => <Dater date={record.createdAt} />}
            defaultSortOrder="descend"
          />
          <Column
            title="Action"
            render={(record: any) => {
              return (
                <span>
                  <EyeFilled
                    onClick={() => {
                      setOpenModal(true);
                      setCurrentResult(record);
                    }}
                  />
                </span>
              );
            }}
          />
        </Table>
        <Pagination
          style={{
            padding: '20px'
          }}
          {...paginationConfig}
        />
      </Card>
    </>
  );
};

export default FormResultsList;

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Input, Form } from 'formik-antd';
import { Row, Col } from 'antd';
import UploadImage from '../../../../components/UploadImage';

export function OrganizationReportPageForm(props: any) {
  return (
    <div>
      <Row gutter={24}>
        <Col sm={5} xs={24}>
          <Form.Item name="logo" label={'Logo'}>
            <UploadImage name="logo" type="organization" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <Form.Item name="reportPageTitle" label="Title" required>
            <Input name="reportPageTitle" placeholder="title" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

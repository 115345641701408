import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { Input, Form } from 'formik-antd';
import { OrganizationSelect } from '../../../../components/OrganizationSelect';
import { useParams } from 'react-router';

const FormDataForm = (props: any) => {
  const { formId } = useParams<any>();
  const type = formId ? 'edit' : 'create';

  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} xs={24}>
          <Form.Item name="title" label="Title" required>
            <Input name="title" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item name="description" label="Description" required>
            <Input.TextArea name="description" />
          </Form.Item>
        </Col>
        {type === 'create' && (
          <Col sm={24} xs={24}>
            <Form.Item name="organizationId" label={'Organization'} required>
              <OrganizationSelect name="organizationId" />
            </Form.Item>
          </Col>
        )}
        <Col sm={24} xs={24}>
          <Form.Item name="form" label="Form (json)" required>
            <Input.TextArea name="form" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default FormDataForm;

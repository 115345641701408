import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import FormResultsList from './FormResultsList';

export const FormResults = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['form-results']}>
      <Route exact path={path} component={FormResultsList} />
    </BaseAdmin>
  );
};

import { Empty } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import { Survey } from 'survey-react';
import 'survey-react/survey.css';
import assessment1 from '../../../admin/reports/assessment-forms/assessment1';
import assessment2 from '../../../admin/reports/assessment-forms/assessment2';

const TestAssessment = () => {
  const { assessmentType } = useParams<{ assessmentType: string }>();
  const form =
    assessmentType === '1'
      ? assessment1
      : assessmentType === '2'
      ? assessment2
      : undefined;

  return <div>{form ? <Survey model={form} /> : <Empty />}</div>;
};

export default TestAssessment;

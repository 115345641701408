import React, { FunctionComponent } from 'react';
import { StepForm, Step } from '../../../components/StepForm';
import * as yup from 'yup';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import {
  FacilityFormType,
  facilityBinder,
  emptyFacility
} from '../../../services/api/Facility/facility.model';
import { Card } from 'antd';
import { FormControllerProps } from '../../../utils/globalTypes';
import { useParams, useHistory } from 'react-router';
import { message } from 'antd';
import { FacilityInformationForm } from './forms/FacilityInformationForm';
import { FacilityEmailInformationForm } from './forms/FacilityEmailInformationForm';
import { phoneValidation } from '../../../utils/helper';
import { FacilityUsersForm } from './forms/FacilityUsersForm';

export const EditFacility = () => {
  const { facId } = useParams<any>();
  const [facility] = useFetchLoad(api.facility.getOne, [facId], [facId]);
  return (
    <FacilityFormController
      type="edit"
      initialValues={facilityBinder(facility ? facility : emptyFacility)}
      submitFn={(data, history) =>
        foldApi(api.facility.updateOne(facId, data))
          .then(() => {
            message.success('Facility updated successfully.');
            history.push('/facilities');
          })
          .catch(() => {
            message.error('Something went wrong!');
          })
      }
    />
  );
};

export const CreatFacility = () => (
  <FacilityFormController
    type="create"
    initialValues={facilityBinder(emptyFacility)}
    submitFn={(data, history) =>
      foldApi(api.facility.creatOne(data))
        .then(() => {
          message.success('Facility created successfully.');
          history.push('/facilities');
        })
        .catch(() => {
          message.error('Something went wrong!');
        })
    }
  />
);

const FacilityFormController: FunctionComponent<
  FormControllerProps<FacilityFormType>
> = ({ type, submitFn, initialValues }) => {
  const history = useHistory();

  const FacilityInformationFormSchema = yup.object().shape({
    name: yup.string().min(3).required(),
    identifier: yup.string().nullable(),
    status: yup.boolean(),
    timezone: yup.string().required(),
    addressUnit: yup.string().nullable(),
    addressStreet: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    zipCode: yup.string().min(5).max(6).required(),
    phone: phoneValidation.nullable(),
    email: yup.string().email().nullable(),
    logo: yup.string().nullable(),
    settings: yup.object().shape({
      useFacilityName: yup.boolean()
    })
  });

  const FacilityEmailInformationFormSchema = yup.object().shape({
    ccEmails: yup.array(
      yup
        .string()
        .email('enter a valid mail.')
        .required('this is a requierd field.')
    ),
    bccEmails: yup.array(
      yup
        .string()
        .email('enter a valid mail.')
        .required('this is a requierd field.')
    )
  });

  return (
    <Card>
      <StepForm<FacilityFormType>
        initialValues={initialValues}
        onSubmit={async x => {
          await submitFn(x, history);
        }}
        editMode={type === 'edit'}
        cancelHref={'/facilities'}
      >
        <Step
          title="Facility Information"
          validationSchema={FacilityInformationFormSchema}
        >
          <FacilityInformationForm />
        </Step>
        <Step
          title="Email Information"
          validationSchema={FacilityEmailInformationFormSchema}
        >
          <FacilityEmailInformationForm />
        </Step>
        <Step
          title="Users Role"
          validationSchema={FacilityEmailInformationFormSchema}
        >
          <FacilityUsersForm />
        </Step>
      </StepForm>
    </Card>
  );
};

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { Select } from 'formik-antd';
import { Form } from 'formik-antd';
import { useFetchLoad } from '../../../../utils/useFetchLoad';
import api from '../../../../services/api/api';
import { useFormikContext } from 'formik';
import { getRole, getUsername } from '../../../../services/auth';
import { roles } from '../UserFormController';

export function UserRoleForm(props: any) {
  const { values } = props;
  const { setFieldValue } = useFormikContext();

  const [facs] = useFetchLoad(
    api.facility.getAll,
    [values.organizations[0]],
    [values.organizations[0]]
  );
  const [orgs] = useFetchLoad(api.organization.getAll, [], []);

  const checkAccess = (x: number, s: string) =>
    x >= roles.indexOf(`${getRole() !== null ? getRole() : 'monitor'}`);

  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} xs={24}>
          <Form.Item name="role" label={'Role'}>
            <Select
              disabled={values.email === getUsername()}
              name="role"
              defaultValue="user"
            >
              {roles.map(
                (r, i) =>
                  checkAccess(i, r) && (
                    <Select.Option key={r} value={r}>
                      {r}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
        {values.role !== 'admin' && (
          <Col sm={24} xs={24}>
            <Form.Item name="organizations[0]" label={'Organization'}>
              <Select
                name="organizations[0]"
                onChange={() => {
                  setFieldValue('facilityIds', []);
                }}
                showSearch
              >
                {orgs &&
                  orgs.map(org => (
                    <Select.Option key={org.id} value={org.id}>
                      {org.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {(values.role === 'monitor' || values.role === 'branchManager') && (
          <Col sm={24} xs={24}>
            <Form.Item name="facilityIds" label={'Facility'}>
              <Select mode="multiple" name="facilityIds" showSearch>
                {facs &&
                  facs.map(fac => (
                    <Select.Option value={fac.id}>{fac.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
}

import React, { createRef, useState } from 'react';
import { Formik, Form } from 'formik';
import Input from '../../../components/Input/Input';
import Submit from '../../../components/Submit/Submit';
import * as yup from 'yup';

import './Signup.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
import { getRequest } from '../../../services/request';
import Outer from '../../OuterPages/Outer';

const RecapKey =
  process.env.NODE_ENV === 'development'
    ? '6LdHl9MZAAAAAJWbxMx4xtpvmvkD1fvKjW5YR16u'
    : '6Le0eNQZAAAAAFBqRNuQFYsnx_-PBphhS_jNK9V4';

const recaptchaRef = createRef<any>();

const validateSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstname: yup.string().min(3).required(),
  lastname: yup.string().min(3).required(),
  businessName: yup.string().required(),
  password: yup
    .string()
    .min(8, 'password must be more than 8 caracters')
    .matches(RegExp(`(?![.\n])`), "a password can't contain white space. ")
    .matches(
      RegExp(`(?=.*[a-z]).*$`),
      'password must have lowercase character.'
    )
    .matches(
      RegExp(`(?=.*[A-Z]).*$`),
      'password must have uppercase character.'
    )
    .matches(RegExp(`(?=.*\\d)`), 'password must have number.')
    .required(),
  repassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], "it's not match with password.")
    .required('Password confirm is required')
});

const Signup = () => {
  const [x, setX] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <Outer title="Signup" goTo={{ title: 'login', url: '/login' }}>
      <div className="signup__content">
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            businessName: '',
            password: '',
            email: ''
          }}
          validationSchema={validateSchema}
          onSubmit={values => {
            setLoading(true);
            const {
              firstname,
              lastname,
              businessName,
              password,
              email
            } = values;
            const request = getRequest();
            request
              .post('/signup', {
                firstName: firstname,
                lastName: lastname,
                username: email,
                organization: { name: businessName },
                password,
                email,
                grecaptchaToken: recaptchaRef.current.getValue()
              })
              .then(res => {
                setLoading(false);
                setX(true);
                history.push('/signup/verify', {
                  email
                });
              })
              .catch(err => {
                console.error(err);
                setLoading(false);
              });
          }}
          render={formikBag => (
            <Form>
              <div className="form_grid">
                <Input name="firstname" type="text" placeholder="First Name" />
                <Input name="lastname" type="text" placeholder="Last Name" />
                <Input name="email" placeholder="Email" type="email" />
                <Input
                  name="businessName"
                  type="text"
                  placeholder="Business Name"
                />
                <Input name="password" type="password" placeholder="Password" />
                <Input
                  name="repassword"
                  placeholder="Retype password"
                  type="password"
                />
                <ReCAPTCHA ref={recaptchaRef} sitekey={RecapKey} />
                <Submit check={x} loading={loading} />
              </div>
            </Form>
          )}
        />
        <span className="signup__content__sub">
          By signing up you accept our
          <Link to="/privacy"> Terms of use</Link>
        </span>
      </div>
    </Outer>
  );
};

export default Signup;

import React from "react";
import qrCode from "qrcode";
export default class QRCode extends React.Component<any, any, any> {
  state = {
    url: ""
  };

  componentDidMount() {
    qrCode.toDataURL(this.props.value, { width: 512, margin: 1 }).then(url => {
      this.setState({ url });
    });
  }

  render() {
    return (
      <span>
        <a href={this.state.url} download={`${this.props.name}.png`}>
          {this.props.children}
        </a>
      </span>
    );
  }
}

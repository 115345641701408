import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { OrganizationsList } from './OrganizationsList';
import { FacilitiesList } from '../facilities/FacilitiesList';
import { TouchpointsList } from '../touchpoints/TouchpointsList';
import { ReportsList } from '../reports/ServiceRequestReportsList';
import { hasRole } from '../../../services/auth';
import {
  CreatOrganization,
  EditOrganization
} from './OrganizationFormController';
import {
  EditTouchpoint,
  CreatTouchpoint
} from '../touchpoints/TouchpointFormController';
import {
  CreatFacility,
  EditFacility
} from '../facilities/FacilityFormController';

export const Organizations = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['organizations']}>
      <Route
        exact
        path={`${path}/:orgId/facilities/:facId/touchpoints/:touchId/reports`}
        component={ReportsList}
      />
      <Route
        exact
        path={`${path}/:orgId/facilities/:facId/touchpoints/:touchId/edit`}
        component={EditTouchpoint}
      />
      <Route
        exact
        path={`${path}/:orgId/facilities/:facId/touchpoints/create`}
        component={CreatTouchpoint}
      />
      <Route
        exact
        path={`${path}/:orgId/facilities/:facId/touchpoints`}
        component={TouchpointsList}
      />
      <Route
        exactFacilitiesEdit
        path={`${path}/:orgId/facilities/:facId/edit`}
        component={EditFacility}
      />
      <Route
        exact
        path={`${path}/:orgId/facilities/create`}
        component={CreatFacility}
      />
      <Route
        exact
        path={`${path}/:orgId/facilities`}
        component={FacilitiesList}
      />
      <Route exact path={path} component={OrganizationsList} />
      <Route exact path={`${path}/:orgId/edit`} component={EditOrganization} />
      {hasRole('admin') && (
        <Route exact path={`${path}/create`} component={CreatOrganization} />
      )}
    </BaseAdmin>
  );
};

import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { Input, DatePicker, Switch, Form } from 'formik-antd';
import { FacilitySelect } from '../../../../components/FacilitySelect';
import { useParams } from 'react-router';

export function TouchpointInformationForm(props: any) {
  const { touchId } = useParams<any>();
  const type = touchId ? 'edit' : 'create';

  return (
    <div>
      <Col sm={10} xs={24} style={{ display: 'none' }}>
        <Form.Item name="token" label="Touchpoint token">
          <Input name="token" placeholder="Touchpoint token" />
        </Form.Item>
      </Col>
      <Row gutter={24}>
        <Col sm={10} xs={24}>
          <Form.Item name="name" label="Touchpoint name" required>
            <Input name="name" placeholder="Touchpoint name" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="facility" label="Facility" required>
            <FacilitySelect name="facilityId" disabled={type === 'edit'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="identifier" label="Touchpoint identifier">
            <Input name="identifier" placeholder="Touchpoint identifier" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="installedAt" label="Install date">
            <DatePicker name="installedAt" showTime placeholder="Select Time" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="status" label="status">
            <Switch name="status" defaultChecked={true} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

import React, { FunctionComponent } from 'react';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import { Input, Form } from 'formik-antd';
import { useFormikContext } from 'formik';
import api, { foldApi } from '../services/api/api';

interface IUploadImageProps {
  name?: string;
  type: 'organization' | 'facility';
}
const UploadImage: FunctionComponent<IUploadImageProps> = ({
  name = 'image',
  type
}) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const setimageSrc = (info: any) => {
    if (type === 'organization') {
      foldApi(api.organization.uploadLogo(info.file))
        .then(res => setFieldValue(name, res.data))
        .catch(err => console.error(err));
    } else {
      foldApi(api.facility.uploadLogo(info.file))
        .then(res => setFieldValue(name, res.data))
        .catch(err => console.error(err));
    }
  };

  const cleearImage = () => setFieldValue(name, '');
  const image = values.logo;

  return (
    <div>
      <Upload
        name="image"
        listType="picture-card"
        className=""
        showUploadList={false}
        beforeUpload={() => false}
        onChange={setimageSrc}
      >
        {image ? (
          <img src={image} alt={name} style={{ width: '100%' }} />
        ) : (
          <PlusOutlined />
        )}
      </Upload>
      {image && (
        <Button type="primary" size="small" ghost onClick={cleearImage}>
          <CloseOutlined />
          clear
        </Button>
      )}
      <Form.Item name={name} style={{ display: 'none' }}>
        <Input name={name} />
      </Form.Item>
    </div>
  );
};

export default UploadImage;

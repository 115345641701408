import React, { useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { hasToken, hasRole } from '../../services/auth';
import { Dashboard } from './dashboard/Dashboard';
import { Users } from './users/Users';
import { Organizations } from './organizations/Organizations';
import { Facilities } from './facilities/Facilities';
import { Touchpoints } from './touchpoints/Touchpoints';
import { Reports } from './reports/Reports';
import { Forms } from './forms/Forms';
import { FormResults } from './formResults/FormResults';

export const Admin = (props: RouteComponentProps) => {
  const { history } = props;

  useEffect(() => {
    if (!hasToken()) {
      history.push('/login');
    }
  }, [history]);

  return (
    <div id="admin">
      <Route exact path="/" component={Dashboard} />
      {!hasRole('monitor') && <Route path="/users" component={Users} />}
      <Route path="/organizations" component={Organizations} />
      <Route path="/facilities" component={Facilities} />
      <Route path="/forms" component={Forms} />
      <Route path="/form-results" component={FormResults} />
      <Route path="/touchpoints" component={Touchpoints} />
      <Route path="/reports/serviceRequest" component={Reports} />
      <Route path="/reports/assessment" component={Reports} />
    </div>
  );
};

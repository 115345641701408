import React from 'react';
import Submit from '../../../components/Submit/Submit';
import Outer from '../../OuterPages/Outer';
import * as yup from 'yup';
import Input from '../../../components/Input/Input';
import { Form, Formik } from 'formik';
import {
  passwordValidation,
  reTypePasswordValidation
} from '../../../utils/helper';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/api/api';

const ResetPassword = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  return (
    <Outer title="Reset password">
      <Formik
        validationSchema={yup.object().shape({
          password: passwordValidation,
          repassword: reTypePasswordValidation
        })}
        initialValues={{ password: '' }}
        onSubmit={values => {
          const { password } = values;
          api.auth
            .reset(token, password)
            .then(res => {
              history.push('/login');
            })
            .catch(err => console.error(err));
        }}
        render={formikBag => (
          <Form>
            <Input
              title="New password"
              name="password"
              type="password"
              placeholder="Password"
            />
            <Input
              title="Re-enter"
              name="repassword"
              placeholder="Retype password"
              type="password"
            />
            <Submit />
          </Form>
        )}
      />
    </Outer>
  );
};

export default ResetPassword;

export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  role?: userRoleType;
  isActive: boolean;
  organizations?: number[];
  facilityIds?: number[];
}

export type userRoleType = 'admin' | 'manager' | 'monitor' | 'branchManager';

export interface UserFormType {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  role?: userRoleType;
  isActive: boolean;
  organizations?: number[];
  facilityIds?: number[];
}

export const emptyUser: UserType = {
  id: 0,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  role: 'monitor',
  isActive: true,
  organizations: []
};

export const userBinder = ({
  username,
  email,
  role,
  isActive,
  firstName,
  lastName,
  organizations,
  facilityIds
}: UserType): UserFormType => ({
  firstName,
  lastName,
  username,
  email,
  role,
  isActive,
  organizations,
  facilityIds
});

import React, { FC } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'formik-antd';
import { useFetchLoad } from '../utils/useFetchLoad';
import api from '../services/api/api';
import { pipe } from 'fp-ts/lib/function';
import apifns from '../services/api/api.functions';

interface FaicilitiesSelectProps {
  name?: string;
  disabled?: boolean;
  multiple?: boolean;
  organizationIds?: number[];
}

export const FacilitySelect: FC<FaicilitiesSelectProps> = ({
  name = 'facility',
  disabled = false,
  multiple,
  organizationIds
}) => {
  const [facilities] = useFetchLoad(
    () =>
      pipe(
        api.facility.getAll(),
        !organizationIds || organizationIds.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizationIds)
      ),
    [],
    []
  );
  return (
    <Select
      name={name}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      defaultValue=""
      style={{ minWidth: '150px' }}
    >
      {facilities &&
        facilities.map(facility => (
          <Select.Option
            key={facility.id}
            value={facility.id}
            disabled={disabled}
          >
            {facility.name}
          </Select.Option>
        ))}
    </Select>
  );
};

import { LinkOutlined } from '@ant-design/icons';
import { Col, Row, Space, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import api from '../../services/api/api';
import { useFetchLoad } from '../../utils/useFetchLoad';
import './buttonsModal.scss';

export type modalFunction = (
  x: string,
  type: 'custom' | 'assessment' | 'form'
) => void;

const useButtonsModal = (facilityId: string) => {
  const [open, setOpen] = useState<boolean>(false);
  const [onC, setOnC] = useState<modalFunction>((x, type) => undefined);
  const [forms, loading] = useFetchLoad(api.form.getAll, [], []);

  const [facility] = useFetchLoad(
    api.facility.getOne,
    [facilityId],
    [facilityId]
  );

  const openModal = (f: modalFunction) => {
    setOpen(true);
    setOnC(() => f);
  };

  interface AssessmentButtonsModal {
    number: number;
    extra?: React.ReactNode;
  }

  const assessment: AssessmentButtonsModal[] = [
    { number: 1 },
    { number: 2, extra: <Tag color="blue">short version</Tag> }
  ];

  const modal = () =>
    !loading ? (
      <Modal
        title="Add Link or Form"
        visible={open}
        onCancel={() => {
          setOpen(false);
        }}
        footer={null}
      >
        <Row gutter={32}>
          <Col md={24}>
            <div
              className="custom_link"
              onClick={() => {
                onC(``, 'custom');
                setOpen(false);
              }}
            >
              <LinkOutlined />
              <div className="custom_link__text">Add Custom Link</div>
            </div>
          </Col>
          {assessment.map(a => (
            <Col md={24} key={a.number}>
              <div className="assessment_button modal_button ">
                <Space>
                  {`Assessment ${a.number}`}
                  {a.extra}
                </Space>

                <Space>
                  <a
                    className="modal_button__top__buttons__preview"
                    href={`/survey/test/assessment/${a.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview
                  </a>
                  <div
                    className="modal_button__top__buttons__add"
                    onClick={() => {
                      onC(`/${a.number}`, 'assessment');
                      setOpen(false);
                    }}
                  >
                    Select
                  </div>
                </Space>
              </div>
            </Col>
          ))}

          {forms && facility ? (
            forms
              .filter(x => x.organizationId === facility.organizationId)
              .map(x => (
                <Col md={24} key={x.id}>
                  <div className="modal_button">
                    <div className="modal_button__top">
                      <div className="modal_button__top__name">{x.title}</div>
                      <div className="modal_button__top__buttons">
                        <a
                          className="modal_button__top__buttons__preview"
                          href={`/survey/test/${x.token}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview
                        </a>

                        <div
                          className="modal_button__top__buttons__add"
                          onClick={() => {
                            onC(`/${x.token}`, 'form');
                            setOpen(false);
                          }}
                        >
                          Select
                        </div>
                      </div>
                    </div>
                    <div className="modal_button__details">{x.description}</div>
                  </div>
                </Col>
              ))
          ) : (
            <div>no forms found</div>
          )}
        </Row>
      </Modal>
    ) : (
      <div />
    );

  return { ButtonModal: modal, oepnModal: openModal };
};

export default useButtonsModal;

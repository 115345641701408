import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Table, Card, Button, Switch } from 'antd';
import { useGet } from '../../../hooks/useGet';
import { roleCheck } from '../../../services/auth';

const { Column } = Table;

export function UsersList(props: RouteComponentProps) {
  const { data, loading } = useGet('/users?sort=id,DESC');
  const {
    match: { url }
  } = props;

  return (
    <Card
      className="items-list-table"
      title="Users"
      extra={
        <Link to={`${url}/create`}>
          <Button type="primary" size="small">
            <PlusOutlined />
          </Button>
        </Link>
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total: any) => `Total ${total} items`,
          size: 'small'
        }}
        loading={loading}
      >
        <Column title="First Name" dataIndex="firstName" />
        <Column title="Last Name" dataIndex="lastName" />
        <Column title="Username" dataIndex="username" />
        <Column title="Role" dataIndex="role" width="5%" />
        <Column
          title="Active"
          width="5%"
          render={(record: any) => (
            <Switch checked={record.isActive} size="small" />
          )}
        />
        {roleCheck('admin', 'manager') && (
          <Column
            title="Action"
            width="10%"
            render={(_: any, record: any) => {
              return (
                <span>
                  <Link to={`${url}/${record.id}/edit`}>
                    <EditFilled />
                  </Link>
                </span>
              );
            }}
          />
        )}
      </Table>
    </Card>
  );
}

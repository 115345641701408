import { Skeleton } from 'antd';
import React, { FC } from 'react';
import { Model, Survey } from 'survey-react';

import api from '../services/api/api';
import { FormResultType } from '../services/api/FormResults/formResults.type';
import { useFetchLoad } from '../utils/useFetchLoad';

interface SurveyResultProps {
  resultId?: string;
  pResult?: FormResultType;
  isSinglePage?: boolean;
  token?: string;
}

const SurveyResult: FC<SurveyResultProps> = ({
  resultId,
  pResult,
  isSinglePage
}) => {
  const [result, resultLoading] = useFetchLoad(
    api.formResults.getOne,
    [resultId],
    [pResult === undefined]
  );

  return result || pResult ? (
    <Survey
      model={new Model(pResult ? pResult.formTemplate : result!.formTemplate)}
      mode="display"
      isSinglePage={isSinglePage}
      data={pResult ? pResult.result : result!.result}
    />
  ) : resultLoading ? (
    <div>
      <Skeleton />
    </div>
  ) : (
    <div>NO DATA</div>
  );
};

export default SurveyResult;

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EditFilled, PlusOutlined, ToolFilled } from '@ant-design/icons';
import { Table, Divider, Card, Button, Switch, Space } from 'antd';
import { useGet } from '../../../hooks/useGet';
import { hasRole, roleCheck } from '../../../services/auth';
import { OrganizationType } from '../../../services/api/Organization/organization.model';
import { compareStringg } from '../../../utils/helper';

const { Column } = Table;

export function OrganizationsList(props: RouteComponentProps) {
  const { data, loading } = useGet('/organizations?sort=id,DESC');
  const {
    match: { url }
  } = props;

  return (
    <Card
      className="items-list-table"
      title="Organizations"
      extra={
        hasRole('admin') && (
          <Link to={`${url}/create`}>
            <Button type="primary" size="small">
              <PlusOutlined />
            </Button>
          </Link>
        )
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total: any) => `Total ${total} items`,
          size: 'small'
        }}
        loading={loading}
      >
        <Column
          title="Name"
          dataIndex="name"
          sorter={compareStringg<OrganizationType>('name')}
        />
        <Column
          title="City"
          dataIndex="city"
          sorter={compareStringg<OrganizationType>('city')}
        />
        <Column
          title="Country"
          dataIndex="country"
          sorter={compareStringg<OrganizationType>('country')}
        />
        {hasRole('admin') && (
          <Column
            title="Status"
            width="5%"
            render={(record: any) => (
              <Switch checked={record.status} size="small" />
            )}
          />
        )}
        <Column
          title="Action"
          width="20%"
          render={(record: any) => {
            return (
              <span>
                <Link to={`/facilities?orgId=${record.id}`}>
                  <ToolFilled />
                  <span> Facilities</span>
                </Link>
                <Divider type="vertical" />
                {roleCheck('admin', 'manager') && (
                  <Link to={`${url}/${record.id}/edit`}>
                    <Button>
                      <Space>
                        <EditFilled />
                        <span>Edit</span>
                      </Space>
                    </Button>
                  </Link>
                )}
              </span>
            );
          }}
        />
      </Table>
    </Card>
  );
}

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import ResultAssessmentSurvey from './ResultAssessmentSurvey/resultAssessmentSurvey';
import ResultFormSurvey from './ResultFormSurvey/resultFormSurvey';
import TestAssessment from './TestAssessment/testAssessmnent';
import TestSurvey from './TestSurvey/testSurvey';

const Survey = () => {
  let match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          path={`${match.url}/test/assessment/:assessmentType`}
          component={TestAssessment}
        />
        <Route path={`${match.url}/test/:formtoken`} component={TestSurvey} />
        <Route
          path={`${match.url}/assessment/:assessmentId`}
          component={ResultAssessmentSurvey}
        />
        <Route
          path={`${match.url}/results/:resId`}
          component={ResultFormSurvey}
        />
      </Switch>
    </>
  );
};

export default Survey;

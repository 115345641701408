import React, { FC, ReactNode } from 'react';
import { Row, Card, Statistic, Space } from 'antd';

interface StatisticCardProps {
  title: string;
  icon?: ReactNode;
}

interface StatisticValueProps {
  value: number | string | undefined;
  title: ReactNode;
  extra?: ReactNode;
}

export const StatisticValue: FC<StatisticValueProps> = ({
  value,
  title,
  extra
}) => {
  return (
    <Space align="start">
      <Statistic title={title} value={value ?? '-'} />
      {extra}
    </Space>
  );
};

export const StatisticCard: FC<StatisticCardProps> = ({
  title,
  icon,
  children
}) => {
  return (
    <div>
      <Card
        title={
          <Space>
            {icon}
            {title}
          </Space>
        }
      >
        <Row justify="space-around">{children}</Row>
      </Card>
    </div>
  );
};

import { DependencyList, useEffect, useState } from 'react';

const useLock = (f: () => void, dep: DependencyList) => {
  const [lock, setLock] = useState<boolean>(false);

  useEffect(() => {
    setLock(false);
    f();
    setLock(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dep, f]);

  return lock;
};
export default useLock;

import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { UsersList } from './UsersList';
import { CreatUser, EditUser } from './UserFormController';

export const Users = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['users']}>
      <Route exact path={path} component={UsersList} />
      <Route exact path={`${path}/:userId/edit`} component={EditUser} />
      <Route exact path={`${path}/create`} component={CreatUser} />
    </BaseAdmin>
  );
};

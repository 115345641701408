import React, { FunctionComponent } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'formik-antd';
import timezones from '../utils/timezones';

interface ITimezoneSelectProps {
  name?: string;
}

export const TimezoneSelect: FunctionComponent<ITimezoneSelectProps> = ({
  name = 'timezone'
}) => {
  return (
    <Select name={name} showSearch>
      {timezones.map((item: any) => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

import { useMemo, useCallback, DependencyList } from 'react';
import { TablePaginationConfig } from 'antd/lib/table';
import qs from 'query-string';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useHistory, useLocation } from 'react-router-dom';
import apifns from '../services/api/api.functions';
import { REQUEST } from '../services/api/api';
import { useFetchLoad } from '../utils/useFetchLoad';
import { flow } from 'fp-ts/lib/function';

const getPage = (query: qs.ParsedQuery<string>) => {
  if (query.page) {
    if (Array.isArray(query.page)) {
      return parseInt(query.page[0], 10);
    } else {
      return parseInt(query.page, 10);
    }
  } else {
    return 1;
  }
};

type TableChangeFuncType = (
  pagination: TablePaginationConfig,
  filters: Record<string, any[] | null>,
  sorter: any,
  extra: TableCurrentDataSource<any>
) => void;

export type ListType<T> = {
  data: T[];
  loading: boolean;
  paginationConfig: TablePaginationConfig;
  orderBy: string;
  orderDir: 'ascend' | 'descend';
  setListData: React.Dispatch<React.SetStateAction<T[] | undefined>>;
  onTableChange: TableChangeFuncType;
};

export const useList = <T, REQ>(
  apireq: () => REQUEST<T[], REQ>,
  deps: DependencyList = [],
  triggers: DependencyList = [],
  {
    cache = 0,
    params: listParam
  }: { limit?: number; cache?: number; params?: any } = {}
): ListType<T> => {
  // ? Parse The query from URL
  const history = useHistory();
  const location = useLocation();

  const path = location.pathname;
  const query = useMemo(() => qs.parse(location.search), [location]);
  const page = useMemo(() => getPage(query), [query]);

  const { orderBy, orderDir } = query;

  const limit =
    query.limit && !Array.isArray(query.limit) ? parseInt(query.limit) : 20;

  const [data, loading, , , headers, setListData] = useFetchLoad(
    () =>
      flow(
        apifns.pagination(page, limit),
        apifns.sort(
          typeof orderBy === 'string' ? orderBy : undefined,
          orderDir === 'ascend' || orderDir === 'descend' ? orderDir : undefined
        )
      )(apireq()),
    [],
    [...deps],
    [orderBy, orderDir, page, limit, ...triggers]
  );

  const combinePath = (pathname: string, search: Record<string, any>) =>
    `${pathname}?${
      search && Object.keys(search).length > 0 ? qs.stringify(search) : ''
    }`;

  const paginationConfig = useMemo<TablePaginationConfig>(
    () => ({
      total: headers && headers['resource-count'],
      size: 'small',
      pageSize: limit,
      position: ['bottomCenter'],
      current: page,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50'],
      showTotal: total => `Total ${total} items`,
      onShowSizeChange: (current, pageSize) => {
        history.push(
          combinePath(path, { ...query, page: current, limit: pageSize })
        );
      },
      onChange: (page, pageSize) => {
        history.push(combinePath(path, { ...query, page, limit: pageSize }));
      }
    }),
    [path, page, history, limit, query, headers]
  );

  const onTableChange: TableChangeFuncType = useCallback(
    (pagination, __, sorter: SorterResult<any>) => {
      if (!sorter.order)
        return history.push(
          combinePath(path, {
            ...query,
            page: 1,
            orderDir: null,
            orderBy: null
          })
        );
      const orderDir = sorter.order;
      const orderBy = sorter.columnKey;
      return history.push(
        combinePath(path, { ...query, page: 1, orderBy, orderDir })
      );
    },
    [history, path, query]
  );

  return {
    data: data ?? [],
    loading: loading,
    paginationConfig: paginationConfig,
    onTableChange: onTableChange,
    setListData: setListData,
    orderBy: typeof orderBy === 'string' ? orderBy : '',
    orderDir:
      orderDir === 'ascend' || orderDir === 'descend' ? orderDir : 'ascend'
  };
};

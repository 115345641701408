import React, { FunctionComponent } from 'react';
import { StepForm, Step } from '../../../components/StepForm';
import * as yup from 'yup';

import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import {
  TouchpointFormType,
  emptyTouchpoint,
  touchpointBinder,
  combineWithDefault
} from '../../../services/api/Touchpoint/touchpoint.model';
import { Card } from 'antd';
import { FormControllerProps } from '../../../utils/globalTypes';
import { useParams, useHistory } from 'react-router';
import { message } from 'antd';
import { TouchpointInformationForm } from './forms/TouchpointInformationForm';
import { TouchpointButtonsForm } from './forms/TouchpointButtonsForm';

export const EditTouchpoint = () => {
  const { touchId } = useParams<any>();
  const [touchpoint] = useFetchLoad(
    api.touchpoint.getOne,
    [touchId],
    [touchId]
  );

  return (
    <TouchpointFormController
      type="edit"
      initialValues={touchpointBinder(
        touchpoint ? combineWithDefault(touchpoint, touchId) : emptyTouchpoint
      )}
      submitFn={(data, history) => {
        delete data.token;
        return foldApi(api.touchpoint.updateOne(touchId, data))
          .then(() => {
            message.success('Touchpoint updated successfully.');
            history.push('/touchpoints');
          })
          .catch(() => {
            message.error('Something went wrong!');
          });
      }}
    />
  );
};

export const CreatTouchpoint = () => (
  <TouchpointFormController
    type="create"
    initialValues={touchpointBinder(combineWithDefault(emptyTouchpoint))}
    submitFn={(data, history) => {
      delete data.token;
      return foldApi(api.touchpoint.creatOne(data))
        .then(() => {
          message.success('Touchpoint created successfully.');
          history.push('/touchpoints');
        })
        .catch(message.error('Something went wrong!'));
    }}
  />
);

const TouchpointFormController: FunctionComponent<
  FormControllerProps<TouchpointFormType>
> = ({ type, submitFn, initialValues }) => {
  const history = useHistory();
  const sch1 = yup.object().shape({
    name: yup.string().min(3).required(),
    identifier: yup.string().nullable(),
    facilityId: yup.string().required(),
    installedAt: yup.string().nullable(),
    status: yup.boolean().required()
  });

  const sch2 = yup.object().shape({
    links: yup.array().of(
      yup.object().shape({
        enable: yup.boolean(),
        url: yup.string().required('this field is required.'),
        title: yup.string().required('this field is required.')
      })
    )
  });

  return (
    <Card>
      <StepForm<TouchpointFormType>
        initialValues={initialValues}
        onSubmit={async x => {
          await submitFn(x, history);
        }}
        editMode={type === 'edit'}
        cancelHref={'/touchpoints'}
      >
        <Step title="Touchpoint Information" validationSchema={sch1}>
          <TouchpointInformationForm />
        </Step>
        <Step title="Touchpoint Buttons" validationSchema={sch2}>
          <TouchpointButtonsForm />
        </Step>
      </StepForm>
    </Card>
  );
};

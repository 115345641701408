import _ from 'lodash';
import { FacilityType } from '../Facility/facility.model';

export type TouchpointButtonsIcon =
  | 'RightCircleOutlined'
  | 'DoubleRightOutlined'
  | 'RetweetOutlined'
  | 'PlayCircleOutlined'
  | 'FullscreenExitOutlined'
  | 'QuestionCircleOutlined'
  | 'CheckCircleOutlined'
  | 'PlusSquareOutlined'
  | 'WarningOutlined'
  | 'FormOutlined'
  | 'CopyOutlined'
  | 'LineChartOutlined'
  | 'GoogleOutlined'
  | 'FacebookOutlined'
  | 'YoutubeOutlined'
  | 'TwitterOutlined'
  | 'InstagramOutlined'
  | 'BulbOutlined'
  | 'BellOutlined'
  | 'CalendarOutlined'
  | 'CameraOutlined'
  | 'CarryOutOutlined'
  | 'CoffeeOutlined'
  | 'CommentOutlined'
  | 'CustomerServiceOutlined'
  | 'EnvironmentOutlined'
  | 'FileOutlined'
  | 'FileDoneOutlined'
  | 'FileTextOutlined'
  | 'LikeOutlined'
  | 'MailOutlined'
  | 'SafetyOutlined'
  | 'SafetyCertificateOutlined'
  | 'PhoneOutlined'
  | 'NotificationOutlined'
  | 'SmileOutlined'
  | 'ToolOutlined'
  | 'ShoppingCartOutlined'
  | 'ShoppingOutlined'
  | 'TagOutlined'
  | 'WifiOutlined'
  | 'UserOutlined'
  | 'ShopOutlined'
  | 'PlusOutlined';

export interface TouchpointButttonType {
  enable: boolean;
  title: string;
  url: string;
  protocol: string;
  icon?: TouchpointButtonsIcon;
  default?: boolean;
  type: 'form' | 'default' | 'custom' | 'assessment';
}

export interface TouchpointType {
  links: TouchpointButttonType[];
  hasServicesRequest: false;
  hasAssessment: boolean;
  id: number;
  name: string;
  facility?: FacilityType;
  token?: string;
  identifier: string;
  status: boolean;
  installedAt: string;
  facilityId: string;
}

export interface TouchpointFormType {
  name: string;
  identifier: string;
  status: boolean;
  installedAt: string;
  facilityId: string;
  links: TouchpointButttonType[];
  token?: string;
}

const defaultLinks = (
  id: string = ':touchpointToken'
): TouchpointButttonType[] => [
  {
    enable: true,
    title: 'Service Request',
    protocol: '',
    url: `${id}/service-request`,
    icon: 'BellOutlined',
    default: true,
    type: 'default'
  }
];

export const combineWithDefault = (
  touchpoint: TouchpointType,
  id?: string
): TouchpointType =>
  Object.assign(touchpoint, touchpoint, {
    links: _.unionWith(
      touchpoint.links,
      defaultLinks(id),
      (x, y) => x.title === y.title
    )
  });

export const emptyTouchpoint: TouchpointType = {
  links: [],
  hasServicesRequest: false,
  hasAssessment: false,
  id: 0,
  name: '',
  token: '',
  identifier: '',
  status: true,
  installedAt: '',
  facilityId: ''
};

export const touchpointBinder = ({
  name,
  identifier,
  status,
  installedAt,
  facilityId,
  links,
  token
}: TouchpointType): TouchpointFormType => ({
  name,
  identifier,
  status,
  installedAt,
  facilityId,
  links,
  token
});

import './style.css';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Admin } from './admin/Admin';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Forgot from './Pages/Forgot/Forgot';
import Verify from './Pages/Verify/Verify';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Activate from './Pages/Activate/Activate';
import Survey from './Pages/Survey/survey';

export const App = () => (
  <div id="app">
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/reset/:token" component={ResetPassword} />
      <Route exact path="/activate/:token" component={Activate} />
      <Route path="/survey" component={Survey} />
      <Route
        exact
        path="/signup/verify"
        render={props => <Verify {...props} status="signup" />}
      />
      <Route
        exact
        path="/forgot/sent"
        render={props => <Verify {...props} status="forgot" />}
      />
      <Route
        exact
        path="/login/verify"
        render={props => <Verify {...props} status="login" />}
      />
      <Route path="/" component={Admin} />
    </Switch>
  </div>
);

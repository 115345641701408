export interface OrganizationType {
  id: number;
  logo: string;
  name: string;
  status: boolean;
  timezone: string;
  reportPageTitle: string;
  addressUnit: string;
  addressStreet: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone: string;
  email: string;
  emailSubject: string;
  emailTo: string;
  ccEmails: string[];
  bccEmails: string[];
}

export interface OrganizationFormType {
  logo: string;
  name: string;
  status: boolean;
  timezone: string;
  reportPageTitle: string;
  addressUnit: string;
  addressStreet: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  phone: string;
  email: string;
  emailSubject: string;
  emailTo: string;
  ccEmails: string[];
  bccEmails: string[];
}

export const emptyOrganization: OrganizationType = {
  id: 0,
  logo: '',
  name: '',
  status: true,
  timezone: 'America/Toronto',
  reportPageTitle:
    'Please submit this form to request service or report issue.',
  addressUnit: '',
  addressStreet: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  phone: '',
  email: '',
  emailSubject:
    '[FacilityName] - [Address] - [TouchpointName] - [TouchpointIdentifier]',
  emailTo: '',
  ccEmails: [],
  bccEmails: []
};

export const organizationBinder = ({
  logo,
  name,
  status,
  timezone,
  reportPageTitle,
  addressUnit,
  addressStreet,
  city,
  state,
  country,
  zipCode,
  phone,
  email,
  emailSubject,
  emailTo,
  ccEmails,
  bccEmails
}: OrganizationType): OrganizationFormType => ({
  logo,
  name,
  status,
  timezone,
  reportPageTitle,
  addressUnit,
  addressStreet,
  city,
  state,
  country,
  zipCode,
  phone,
  email,
  emailSubject,
  emailTo,
  ccEmails,
  bccEmails
});

import React, { useEffect } from 'react';
import { Select } from 'formik-antd';
import { useFetchLoad } from '../utils/useFetchLoad';
import api from '../services/api/api';
import { useFormikContext } from 'formik';
import { roleCheck } from '../services/auth';

export const OrganizationSelect = ({
  name = 'organization',
  disable,
  multiple
}: {
  name?: string;
  disable?: boolean;
  multiple?: boolean;
}) => {
  const [organizations] = useFetchLoad(api.organization.getAll, [], []);

  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (
      organizations &&
      organizations.length > 0 &&
      values[name] === undefined &&
      !roleCheck('admin')
    ) {
      setFieldValue(name, organizations[0].id);
    }
  }, [organizations, name, setFieldValue, values]);

  return (
    <Select
      loading={!organizations}
      defaultValue={organizations ? organizations[0].id : ''}
      name={name}
      disabled={disable}
      showSearch
      mode={multiple ? 'multiple' : undefined}
      style={{ minWidth: '150px' }}
    >
      {organizations &&
        organizations.map(org => (
          <Select.Option value={org.id}>{org.name}</Select.Option>
        ))}
    </Select>
  );
};

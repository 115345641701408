import { makeRequest, methods } from '../api';
import { FacilityFormType, FacilityType } from './facility.model';

const url = '/facilities';

const getAll = () => {
  return makeRequest<FacilityType[]>(url, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<FacilityType>(`${url}/${id}`, methods.get);
};

const deleteOne = (id: string) => {
  return makeRequest(`${url}/${id}`, methods.delete);
};

const creatOne = (data: FacilityFormType) => {
  return makeRequest(`${url}`, methods.post, data);
};

const updateOne = (id: string, data: Partial<FacilityFormType>) => {
  return makeRequest(`${url}/${id}`, methods.put, data);
};

const uploadLogo = (file: File) => {
  const form = new FormData();
  form.append('logo', file);
  return makeRequest(`${url}/upload-logo`, methods.post, form);
};

const statistic = () => {
  return makeRequest<{ total: number; active: number; deactive: number }>(
    `${url}/other/statistic`,
    methods.get
  );
};

const facilityApi = {
  getAll,
  getOne,
  deleteOne,
  creatOne,
  updateOne,
  uploadLogo,
  statistic
};

export default facilityApi;

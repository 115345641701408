import { makeRequest, methods } from '../api';
import { AssessmentType } from './assessment.model';

const url = '/assessment';

const getAll = () => {
  return makeRequest<AssessmentType[]>(`${url}`, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<AssessmentType>(`${url}/${id}`, methods.get);
};

const updateTemp = (id: number, bodyTemp: number) => {
  return makeRequest<AssessmentType>(`${url}/setTemp/${id}`, methods.put, {
    bodyTemp
  });
};

const sendComment = (id: number, comment: string) => {
  return makeRequest<AssessmentType>(`${url}/addComment/${id}`, methods.put, {
    comment
  });
};

const statistic = () => {
  return makeRequest<{ active: number }>(`${url}/other/statistic`, methods.get);
};
const monthlyStatistic = () => {
  return makeRequest<any>(`${url}/other/monthly-statistic`, methods.get);
};

const dailyStatistic = () => {
  return makeRequest<any>(`${url}/other/daily-statistic`, methods.get);
};

const assessmentApi = {
  getAll,
  getOne,
  updateTemp,
  sendComment,
  statistic,
  monthlyStatistic,
  dailyStatistic
};

export default assessmentApi;

import axios from 'axios';
import { axiosInterceptors } from './refreshToken';
export const siteUrl =
  process.env.NODE_ENV === 'production'
    ? 'http://scanortap.com'
    : 'http://localhost:3002';

// LocalstorageService

// Add a request interceptor

export const getRequest = () => {
  const axiosIns = axios.create({
    baseURL: `/api`
    // headers: authHeader()
  });

  axiosIns.interceptors.response.use(
    axiosInterceptors(axiosIns).response.ok,
    axiosInterceptors(axiosIns).response.error
  );

  axiosIns.interceptors.request.use(
    axiosInterceptors(axiosIns).request.ok,
    axiosInterceptors(axiosIns).request.error
  );

  return axiosIns;
};

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { EmailListInput } from '../../../../components/EmailListInput';
import { Input, Form } from 'formik-antd';

export function OrganizationEmailInformationForm(props: any) {
  const { values } = props;
  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} xs={24}>
          <Form.Item name="emailSubject" label={'Email subject'} required>
            <Input name="emailSubject" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item name="emailTo" label={'Email to'} required>
            <Input name="emailTo" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={24}>
          <EmailListInput
            name="ccEmails"
            label="ccEmail"
            emails={values.ccEmails}
          />
        </Col>
        <Col sm={12} xs={24}>
          <EmailListInput
            name="bccEmails"
            label="bccEmail"
            emails={values.bccEmails}
          />
        </Col>
      </Row>
    </div>
  );
}

import { makeRequest, methods } from '../api';
import { FormResultType } from './formResults.type';

const url = '/form-results';

const getAll = (...forms: string[]) => {
  const params =
    forms.length > 0 && forms[0] !== undefined
      ? `?formTokens=[${forms.map(x => '"' + x + '"')}]`
      : '';
  return makeRequest<FormResultType[]>(url + params, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<FormResultType>(`${url}/${id}`, methods.get);
};

const sendComment = (id: number, comment: string) => {
  return makeRequest<FormResultType>(`${url}/addComment/${id}`, methods.put, {
    comment
  });
};
const statistic = () => {
  return makeRequest<{ active: number }>(`${url}/other/statistic`, methods.get);
};

const monthlyStatistic = () => {
  return makeRequest<any>(`${url}/other/monthly-statistic`, methods.get);
};

const dailyStatistic = () => {
  return makeRequest<{ active: number }>(
    `${url}/other/daily-statistic`,
    methods.get
  );
};

const formResApi = {
  getAll,
  getOne,
  sendComment,
  statistic,
  monthlyStatistic,
  dailyStatistic
};
export default formResApi;

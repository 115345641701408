import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/logob.svg';
import './Outer.scss';

interface OuterProps {
  title?: string;
  goTo?: { title: string; url: string };
}

const Outer: FunctionComponent<OuterProps> = ({ children, title, goTo }) => {
  return (
    <div className="outer_page">
      <div className="outer_page__logo">
        <a href={'http://scanortap.com'}>
          <Logo />
        </a>
      </div>
      <div className="outer_page__content">
        <div className="outer_page__content__card">
          {(title || goTo) && (
            <div className="outer_page__content__card__topbar">
              <span className="outer_page__content__card__topbar__title">
                {title}
              </span>
              <span className="outer_page__content__card__topbar__action">
                {goTo && <Link to={goTo.url}>{goTo.title}</Link>}
              </span>
            </div>
          )}
          <div className="outer_page__content__card__content">{children}</div>
        </div>
      </div>
      <div className="outer_page__footer">
        Copyright © 2021 Press'nXPress. All Rights Reserved.
      </div>
    </div>
  );
};

export default Outer;

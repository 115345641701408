import React, { useState, useMemo, useRef } from 'react';
import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Steps, message, Col, Row, Divider } from 'antd';
import { Form } from 'formik-antd';
import keys from 'ramda/es/keys';
import { isArray } from 'lodash';
import { ButtonProps } from 'antd/lib/button';

const filterChildren = (
  values: any,
  children: React.ReactElement[] | React.ReactElement
) =>
  React.isValidElement(children)
    ? [children]
    : children.filter(x => (x.props.canShow ? x.props.canShow(values) : true));

const NextButton = ({
  onClick,
  enabled
}: {
  enabled: boolean;
  onClick: () => void;
}) => (
  <Field>
    {({ form: { isSubmitting, errors, validateForm, values } }: FieldProps) => {
      return (
        <Button
          loading={isSubmitting}
          disabled={
            !enabled || Object.keys(errors).length !== 0 || isSubmitting
          }
          block
          onClick={() => {
            // tslint:disable-next-line:no-shadowed-variable
            validateForm(values).then(errors => {
              return Object.keys(errors).length === 0 ? onClick() : errors;
            });
          }}
        >
          Next
        </Button>
      );
    }}
  </Field>
);

const CustomSubmitButton = ({ children, ...restProps }: ButtonProps) => (
  <Field>
    {({
      form: { isSubmitting, isValid, dirty, submitCount, errors }
    }: FieldProps) => (
      <Button
        loading={isSubmitting}
        type="primary"
        htmlType="submit"
        disabled={
          !isValid &&
          (dirty || submitCount > 0) &&
          !isFormikArrayProblem(errors)
        }
        {...restProps}
      >
        {children}
      </Button>
    )}
  </Field>
);

const isFormikArrayProblem = (e: any): boolean =>
  Object.keys(e).length !== 0 &&
  isArray(e[Object.keys(e)[0]]) &&
  e[Object.keys(e)[0]].length === 1
    ? e[Object.keys(e)[0]][0] === undefined
    : false;

const PreviousButton = ({
  onClick,
  enabled
}: {
  enabled: boolean;
  onClick: () => void;
}) => (
  <Field>
    {({ form: { isSubmitting, errors, validateForm, values } }: FieldProps) => {
      return (
        <Button
          loading={isSubmitting}
          disabled={
            !enabled ||
            (Object.keys(errors).length !== 0 &&
              !isFormikArrayProblem(errors)) ||
            isSubmitting
          }
          block
          onClick={() => {
            validateForm(values);
            if (
              Object.keys(errors).length === 0 ||
              isFormikArrayProblem(errors)
            ) {
              onClick();
            }
          }}
        >
          Prev
        </Button>
      );
    }}
  </Field>
);

// tslint:disable-next-line:only-arrow-functions
export const StepForm = function <T>({
  initialValues,
  children,
  onSubmit,
  editMode = false,
  cancelHref = '/'
}: {
  cancelHref?: string;
  editMode?: boolean;
  initialValues: T;
  children: React.ReactElement[] | React.ReactElement;
  onSubmit: (values: T, formikActions: FormikHelpers<T>) => void;
}) {
  const [current, setCurrent] = useState(0);
  const [finalSubmit, setFinalSubmit] = useState(false);

  const changeTo = (curr: number, form: FormikProps<any>) =>
    form
      .validateForm(form.values)
      .then(result =>
        keys(result).length === 0
          ? setCurrent(curr)
          : Object.keys(result).map(k => message.error(result[k]))
      )
      .catch(err => console.error(err));

  const steps = useMemo(() => {
    return (
      <Field>
        {({ form }: FieldProps) => {
          return (
            <Steps
              current={current}
              onChange={editMode ? curr => changeTo(curr, form) : undefined}
            >
              {React.isValidElement(children) ? (
                <Steps.Step
                  title={children.props.title}
                  key={children.props.title}
                />
              ) : (
                filterChildren(form.values, children).map((x: any) => (
                  <Steps.Step title={x.props.title} key={x.props.title} />
                ))
              )}
            </Steps>
          );
        }}
      </Field>
    );
  }, [children, current, editMode]);

  const childrenRef = useRef(children);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, formikActions: any) => {
          if (
            !React.isValidElement(childrenRef.current) &&
            (current === childrenRef.current.length - 1 || finalSubmit)
          ) {
            setFinalSubmit(false);
            formikActions.validateForm(values).then(() => {
              onSubmit(values, formikActions);
            });
          }
          formikActions.setSubmitting(false);
        }}
        validationSchema={() =>
          React.isValidElement(childrenRef.current)
            ? childrenRef.current.props.validationSchema
            : childrenRef.current[current]!.props.validationSchema
        }
        render={props => (
          <Form>
            <div style={{ marginBottom: '30px' }}>{steps}</div>

            <Field>
              {({ form: { values } }: FieldProps) => {
                childrenRef.current = filterChildren(values, children);

                return React.isValidElement(childrenRef.current)
                  ? React.cloneElement<any>(childrenRef.current, {
                      values
                    })
                  : React.cloneElement(childrenRef.current[current], {
                      values
                    });
              }}
            </Field>
            <Divider />
            <Row>
              <Col
                xl={{ span: 10, offset: 14 }}
                md={{ span: 12, offset: 12 }}
                sm={{ span: 24, offset: 0 }}
              >
                <Row
                  gutter={{ xl: 8, xs: 0 }}
                  style={{ display: 'flex', justifyContent: 'right' }}
                >
                  <Col>
                    <Button
                      type="link"
                      className="cancel-button"
                      href={cancelHref}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <PreviousButton
                      enabled={current > 0}
                      onClick={() => setCurrent(cur => cur - 1)}
                    />
                  </Col>
                  <Col>
                    {!React.isValidElement(childrenRef.current) && (
                      <NextButton
                        enabled={current < childrenRef.current.length - 1}
                        onClick={() => setCurrent(cur => cur + 1)}
                      />
                    )}
                  </Col>
                  <Col md={10}>
                    {!editMode &&
                      (React.isValidElement(childrenRef.current) ||
                        current === childrenRef.current.length - 1) && (
                        <CustomSubmitButton block type="primary">
                          Submit
                        </CustomSubmitButton>
                      )}

                    {editMode && (
                      <CustomSubmitButton
                        block
                        type="primary"
                        onClick={() => setFinalSubmit(true)}
                      >
                        Update
                      </CustomSubmitButton>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export const Step = (props: {
  title: string | React.ReactChildren;
  validationSchema?: any;
  canShow?: (values: any) => boolean;
  children: any;
  values?: any;
}) => {
  return React.cloneElement(props.children, { values: props.values });
};

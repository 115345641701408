import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Input from '../../../components/Input/Input';
import Submit from '../../../components/Submit/Submit';
import './Forgot.scss';
import { useHistory } from 'react-router-dom';
import Outer from '../../OuterPages/Outer';
import * as yup from 'yup';
import api from '../../../services/api/api';

const Forgot = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  return (
    <Outer title="Forgot password" goTo={{ title: 'login', url: '/login' }}>
      <Formik
        validationSchema={yup.object().shape({
          email: yup.string().email().required()
        })}
        initialValues={{ email: '' }}
        onSubmit={values => {
          setLoading(true);
          const { email } = values;
          api.auth
            .forgot(email)
            .then(res => {
              setLoading(false);
              history.push('/forgot/sent', { email: email });
            })
            .catch(err => {
              setLoading(false);
            });
        }}
        render={formikBag => (
          <Form>
            <Input
              name="email"
              title="Email"
              placeholder="joe@example.com"
              type="email"
            />
            <Submit loading={loading} />
          </Form>
        )}
      />
    </Outer>
  );
};

export default Forgot;

import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { hasRole } from '../../../services/auth';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { CreatForm, EditForm } from './FormFormController';
import FormsList from './FormsList';

export const Forms = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['forms']}>
      <Route exact path={path} component={FormsList} />
      <Route exact path={`${path}/:formId/edit`} component={EditForm} />
      {hasRole('admin') && (
        <Route exact path={`${path}/create`} component={CreatForm} />
      )}
    </BaseAdmin>
  );
};

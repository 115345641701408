import React, { FC } from 'react';
import Outer from '../../OuterPages/Outer';
import MessageCard from '../MessageCard/MessageCard';
import { ReactComponent as Mail } from '../../../assets/svg/mail.svg';
import useTimer from '../../../components/Timer/Timer';
import './Verify.scss';
import { useLocation } from 'react-router';
import api from '../../../services/api/api';

type statusType = 'login' | 'signup' | 'forgot';

interface VerifyPorps {
  status: statusType;
}

const Verify: FC<VerifyPorps> = ({ status }) => {
  const [Timer, done, , reset] = useTimer(120, status === 'signup');
  const location = useLocation<{ email: string }>();

  const data = {
    login: {
      title: 'you haven’t verified your account yet.',
      description:
        'before you log in, you have to activate your account with the verification link we sent to your email.',
      f: () => {
        if (location.state && location.state.email) {
          api.auth.sendVerify(location.state.email).then(res => {
            reset();
          });
        }
      }
    },
    signup: {
      title: 'Verification Email Sent !',
      description:
        'We’ve sent you an email that contains activation link. You should activate your account before login. This link expires in 20 minutes',
      f: () => {
        if (location.state && location.state.email) {
          api.auth.sendVerify(location.state.email).then(res => {
            reset();
          });
        }
      }
    },
    forgot: {
      title: 'Check your email to reset your password',
      description:
        'We sent you an email with the reset password link to change your password.',
      f: () => {
        if (location.state && location.state.email) {
          api.auth.forgot(location.state.email).then(res => {
            reset();
          });
        }
      }
    }
  };
  return (
    <div>
      <Outer>
        <>
          <MessageCard
            title={data[status].title}
            description={data[status].description}
            Icon={Mail}
            mail={location.state.email}
          />
          <div className="verify">
            {done ? (
              <div className="verify__button" onClick={data[status].f}>
                send email again
              </div>
            ) : (
              <Timer />
            )}
          </div>
        </>
      </Outer>
    </div>
  );
};

export default Verify;

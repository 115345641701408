import React, { FunctionComponent } from 'react';
import { StepForm, Step } from '../../../components/StepForm';
import * as yup from 'yup';
import { UserInformationForm } from './forms/UserInformationForm';
import { UserRoleForm } from './forms/UserRoleForm';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import {
  UserFormType,
  userBinder,
  emptyUser
} from '../../../services/api/User/user.model';
import { Card } from 'antd';
import { FormControllerProps } from '../../../utils/globalTypes';
import { useParams, useHistory } from 'react-router';
import { message } from 'antd';

export const roles = ['admin', 'manager', 'branchManager', 'monitor'];

export const EditUser = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user] = useFetchLoad(api.user.getOne, [userId], [userId]);
  return (
    <UserFormController
      type="edit"
      initialValues={userBinder(user ? user : emptyUser)}
      submitFn={(data, history) =>
        foldApi(api.user.updateOne(userId, data))
          .then(() => {
            message.success('User updated successfully.');
            history.push('/users');
          })
          .catch(() => {
            message.error('Something went wrong!');
          })
      }
    />
  );
};

export const CreatUser = () => (
  <UserFormController
    type="create"
    initialValues={userBinder(emptyUser)}
    submitFn={(data, history) =>
      foldApi(api.user.creatOne(data))
        .then(() => {
          message.success('User created successfully.');
          history.push('/users');
        })
        .catch(() => {
          message.error('Something went wrong!');
        })
    }
  />
);

const UserFormController: FunctionComponent<
  FormControllerProps<UserFormType>
> = ({ type, submitFn, initialValues }) => {
  const history = useHistory();
  const validateSchema = yup.object().shape({
    username: yup
      .string()
      .email('must be a valid email')
      .required('Email is required.'),
    role: yup.string().required(),
    password: yup
      .string()
      .min(8, 'password must be more than 8 caracters')
      .matches(RegExp(`(?![.\n])`), "a password can't contain white space. ")
      .matches(
        RegExp(`(?=.*[a-z]).*$`),
        'password must have lowercase character.'
      )
      .matches(
        RegExp(`(?=.*[A-Z]).*$`),
        'password must have uppercase character.'
      )
      .matches(RegExp(`(?=.*\\d)`), 'password must have number.')
  });

  return (
    <Card>
      <StepForm<UserFormType>
        initialValues={initialValues}
        onSubmit={async x => {
          await submitFn(x, history);
        }}
        editMode={type === 'edit'}
        cancelHref={'/users'}
      >
        <Step title="User Information" validationSchema={validateSchema}>
          <UserInformationForm />
        </Step>
        <Step title="User Role" validationSchema={validateSchema}>
          <UserRoleForm />
        </Step>
      </StepForm>
    </Card>
  );
};

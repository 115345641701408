import Axios from 'axios';
import { BASE, methods } from '../api';
const axios = Axios.create();

const makeRequest = <T>(
  url: string,
  method: methods,
  data: {} = {},
  params: {} = {},
  headers: {} = {}
) =>
  axios
    .request<T>({
      url: `${BASE}${url}`,
      method,
      data,
      params,
      headers
    })
    .then(res => res.data)
    .catch();

interface LoginResType {
  access_token: string;
  role: string;
}

const login = (email: string, password: string) =>
  makeRequest<LoginResType>(`/login`, methods.post, { email, password });

const signup = (email: string, password: string) => {
  const data = { email, password };
  return makeRequest<LoginResType>(`/login`, methods.post, data);
};

const sendVerify = (email: string) => {
  const data = { email };
  return makeRequest(`/users/send-email-verification`, methods.post, data);
};
const verifyEmail = (token: string) => {
  return makeRequest(`/users/verify-email?token=${token}`, methods.post);
};
const forgot = (email: string) => {
  const data = { email };
  return makeRequest(`/password/forgot`, methods.post, data);
};
const reset = (token: string, password: string) => {
  const data = { password };
  return makeRequest(`/password/reset?token=${token}`, methods.post, data);
};

const authApi = {
  login,
  sendVerify,
  verifyEmail,
  forgot,
  reset,
  signup
};
export default authApi;

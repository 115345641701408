import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Divider, Space, Popover } from 'antd';
import { Input, Switch, Form } from 'formik-antd';
import { TimezoneSelect } from '../../../../components/TimezoneSelect';
import { OrganizationSelect } from '../../../../components/OrganizationSelect';
import { useParams } from 'react-router';
import UploadImage from '../../../../components/UploadImage';
import { InfoCircleTwoTone } from '@ant-design/icons';

export function FacilityInformationForm() {
  const { facId } = useParams<any>();
  const type = facId ? 'edit' : 'create';

  return (
    <div>
      <Row gutter={24}>
        <Col sm={10} xs={24}>
          <Form.Item name="name" label={'Facility name'} required>
            <Input name="name" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="organizationId" label={'Organization'} required>
            <OrganizationSelect
              name="organizationId"
              disable={type === 'edit'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="timezone" label={'Timezone'} required>
            <TimezoneSelect name="timezone" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="identifier" label={'Facility identifier'}>
            <Input name="identifier" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="phone" label={'Phone'}>
            <Input name="phone" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="email" label={'Email'}>
            <Input name="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24}>
          <Form.Item name="status" label={'Status'}>
            <Switch name="status" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Address</Divider>
      <Row gutter={24}>
        <Col sm={18} xs={24}>
          <Form.Item name="addressStreet" label={'Street address'} required>
            <Input name="addressStreet" />
          </Form.Item>
        </Col>
        <Col sm={6} xs={24}>
          <Form.Item name="addressUnit" label={'Unit/Apt'}>
            <Input name="addressUnit" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="zipCode" label={'Zip code/Postal code'} required>
            <Input name="zipCode" placeholder="Zip code/Postal code" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="city" label={'City'} required>
            <Input name="city" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="state" label={'State/Province'} required>
            <Input name="state" placeholder="State/Province" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="country" label={'Country'} required>
            <Input name="country" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Logo</Divider>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item
            name="logo"
            label={
              <Space>
                Logo
                <Popover content="Add logo to show facility logo instead of organization logo in touchpoint landing page.">
                  <InfoCircleTwoTone />
                </Popover>
              </Space>
            }
          >
            <UploadImage name="logo" type="facility" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Setting</Divider>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item
            name="settings.useFacilityName"
            label={
              <Space>
                Use Facility Name
                <Popover content="Enable to show facility name instead of organization name in touchpoint landing page.">
                  <InfoCircleTwoTone />
                </Popover>
              </Space>
            }
          >
            <Switch name="settings.useFacilityName" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

import React from 'react';
import { useParams } from 'react-router';
import 'survey-react/survey.css';
import SurveyResult from '../../../../components/SurveyResult';

const ResultFormSurvey = () => {
  const { resId } = useParams<{ resId: string }>();

  return <SurveyResult resultId={resId} />;
};

export default ResultFormSurvey;

import { Column } from '@ant-design/charts';
import { Card } from 'antd';
import { pipe } from 'fp-ts/lib/function';
import React, { FC } from 'react';
import api from '../../../services/api/api';
import apifns from '../../../services/api/api.functions';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import { months } from './AssessmentMonthly';

interface FormResultMonthlyProps {
  year: number;
  organizations: number[];
  facilities: number[];
}

const FormResultMonthly: FC<FormResultMonthlyProps> = ({
  year,
  organizations,
  facilities
}) => {
  const [formResultMonthly, loading] = useFetchLoad(
    () =>
      pipe(
        api.formResults.monthlyStatistic(),
        apifns.singleFilter('year', year.toString()),
        organizations.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizations),
        facilities.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('facilityIds', ...facilities)
      ),
    [year, organizations, facilities],
    [year, organizations, facilities]
  );

  const data =
    formResultMonthly &&
    formResultMonthly.reduce(
      (acu: any, curr: any) => [
        ...acu,
        {
          month: months[curr.month],
          count: curr.count > 0 ? curr.count : undefined
        }
      ],
      []
    );
  return (
    <Card title="Form Result">
      {
        <Column
          data={data ?? []}
          xField="month"
          yField="count"
          loading={loading}
          height={300}
          color={'#65789B'}
          label={{
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' }
            ]
          }}
        />
      }
    </Card>
  );
};

export default FormResultMonthly;

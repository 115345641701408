import React from 'react';
import { useParams } from 'react-router';
import { Model, Survey } from 'survey-react';
import 'survey-react/survey.css';
import api from '../../../../services/api/api';
import { useFetchLoad } from '../../../../utils/useFetchLoad';

const TestSurvey = () => {
  const { formtoken } = useParams<{ formtoken: string }>();
  const [survey] = useFetchLoad(api.form.getWithToken, [formtoken], []);

  return <div>{survey && <Survey model={new Model(survey.form)} />}</div>;
};

export default TestSurvey;

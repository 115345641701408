import { makeRequest, methods } from '../api';
import { OrganizationType, OrganizationFormType } from './organization.model';

const url = '/organizations';

const getAll = () => {
  return makeRequest<OrganizationType[]>(`${url}`, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<OrganizationType>(`${url}/${id}`, methods.get);
};

const deleteOne = (id: string) => {
  return makeRequest(`${url}/${id}`, methods.delete);
};

const creatOne = (data: OrganizationFormType) => {
  return makeRequest(`${url}`, methods.post, data);
};

const updateOne = (id: string, data: OrganizationFormType) => {
  return makeRequest(`${url}/${id}`, methods.put, data);
};

const uploadLogo = (file: File) => {
  const form = new FormData();
  form.append('logo', file);
  return makeRequest(`${url}/upload-logo`, methods.post, form);
};

const orgApi = {
  getAll,
  creatOne,
  updateOne,
  getOne,
  deleteOne,
  uploadLogo
};

export default orgApi;

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Card, Button, Modal, Col, Row, Badge, Pagination } from 'antd';
import { Input, Form, SubmitButton } from 'formik-antd';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import { AssessmentType } from '../../../services/api/AssessmentReport/assessment.model';
import { Formik } from 'formik';
import {
  CloseOutlined,
  CommentOutlined,
  EditOutlined,
  EyeFilled,
  PrinterFilled,
  SaveOutlined
} from '@ant-design/icons';
import { assessmentFix, tempFormSchema } from '../../../utils/helper';
import { Survey } from 'survey-react';

import assessment1 from './assessment-forms/assessment1';
import assessment2 from './assessment-forms/assessment2';
import Comments from '../../../components/Comments/comments.component';
import Dater from '../../../components/Dater/Dater';
import { useList } from '../../../hooks/useList';
import useLock from '../../../hooks/useLock';
import { pipe } from 'fp-ts/lib/function';
import apifns from '../../../services/api/api.functions';
import Search from 'antd/lib/input/Search';

const { Column } = Table;

export function AssessmentReportList() {
  const loc = useLocation();
  const [touchId, setTouchId] = useState<string | null | undefined>();
  const [search, setSearch] = useState<string | undefined>();

  const lock = useLock(() => {
    const x = new URLSearchParams(loc.search).get('touchId');
    if (x === null || x === undefined) {
      setTouchId(undefined);
    } else {
      setTouchId(x);
    }
  }, []);

  const {
    data,
    loading,
    paginationConfig,
    setListData,
    onTableChange
  } = useList(
    () =>
      pipe(
        api.assessment.getAll(),
        apifns.arrayFilter(
          'touchpointIds',
          touchId ? parseInt(touchId) : undefined
        ),
        apifns.search(search)
      ),
    [lock],
    [touchId, search]
  );

  const [editRow, setEditRow] = useState(-1);

  return (
    <>
      <Card
        className="items-list-table"
        title={
          <>
            Assessment Reports
            {touchId && <ClearButton touchId={touchId} />}
          </>
        }
        extra={
          <Search onSearch={setSearch} placeholder="Search name or email" />
        }
      >
        <Table
          rowKey={(record: any) => record.id}
          dataSource={data}
          loading={loading}
          onChange={onTableChange}
          pagination={false}
          expandable={{
            expandIconColumnIndex: 10,
            expandIcon: ({ expanded, onExpand, record }) =>
              record.comments.length > 0 ? (
                <Badge dot>
                  <CommentOutlined onClick={e => onExpand(record, e)} />
                </Badge>
              ) : (
                <CommentOutlined onClick={e => onExpand(record, e)} />
              ),
            expandedRowRender: record => (
              <Comments
                data={record}
                onSubmit={(values: any, { resetForm }: any) => {
                  return foldApi(
                    api.assessment.sendComment(record.id, values.comment)
                  )
                    .then(newData => {
                      if (data)
                        setListData(
                          data.map(oldData =>
                            oldData.id === newData.data.id
                              ? { ...oldData, comments: newData.data.comments }
                              : oldData
                          )
                        );
                      resetForm({});
                      return true;
                    })
                    .catch(console.error);
                }}
              />
            )
          }}
        >
          <Column
            title="First Name"
            dataIndex="firstname"
            sorter
            key="firstname"
          />
          <Column
            title="Last Name"
            dataIndex="lastname"
            sorter
            key="lastname"
          />
          <Column
            title="Form Type"
            render={(x: AssessmentType) =>
              x.formId === 2 ? 'Type 2' : 'Type 1'
            }
            sorter
            key="formId"
          />
          <Column title="Email" dataIndex="email" sorter key="email" />
          <Column title="Type" dataIndex="type" sorter key="type" />
          <Column
            title="Facility"
            dataIndex={['touchpoint', 'facility', 'name']}
            sorter
            key="facility"
          />
          <Column
            title="Touchpoint"
            dataIndex={['touchpoint', 'name']}
            sorter
            key="touchpoint"
          />
          <Column
            title="Certified"
            render={(record: any) => (record.certified ? 'Yes' : 'No')}
            sorter
            key="certified"
          />
          <Column
            title="Date"
            defaultSortOrder="descend"
            render={(record: any) => <Dater date={record.createdAt} />}
            sorter
            key="createdAt"
          />
          <Column
            title="Temp"
            render={(record: AssessmentType) =>
              record.id !== editRow ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div>{record.bodyTemp ? record.bodyTemp : '-'}</div>
                  <div style={{ width: '20px', height: '0px' }} />
                  {editRow === -1 && (
                    <Button
                      onClick={() => {
                        setEditRow(record.id);
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  )}
                </div>
              ) : (
                <>
                  <Formik
                    validationSchema={tempFormSchema}
                    onSubmit={x => {
                      if (data) {
                        foldApi(
                          api.assessment.updateTemp(
                            record.id,
                            parseFloat(x.bodyTemp)
                          )
                        ).then(newData => {
                          if (data)
                            setListData(
                              data.map(oldData =>
                                oldData.id === newData.data.id
                                  ? {
                                      ...oldData,
                                      bodyTemp: newData.data.bodyTemp,
                                      comments: newData.data.comments
                                    }
                                  : oldData
                              )
                            );
                          setEditRow(-1);
                          return true;
                        });
                      }
                    }}
                    initialValues={{
                      bodyTemp: `${record.bodyTemp ? record.bodyTemp : ''}`
                    }}
                    render={() => (
                      <Form
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Input
                          name="bodyTemp"
                          placeholder="tempbody"
                          style={{ width: '50px' }}
                        />
                        <div style={{ width: '20px', height: '0px' }} />
                        <SubmitButton>
                          <SaveOutlined />
                        </SubmitButton>
                        <Button
                          onClick={() => {
                            setEditRow(-1);
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Form>
                    )}
                  />
                </>
              )
            }
          />
          <Column
            title="Action"
            render={(record: AssessmentType) => (
              <AssessmentModal record={record}></AssessmentModal>
            )}
          />
        </Table>
        <Pagination
          style={{
            padding: '20px'
          }}
          {...paginationConfig}
        />
      </Card>
    </>
  );
}

const AssessmentModal = ({ record }: { record: AssessmentType }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const secondFormId = 2;

  const form = record.formId === secondFormId ? assessment2 : assessment1;
  return (
    <>
      <span>
        <EyeFilled onClick={setOpenModal.bind(null, true)} />
      </span>
      {openModal && (
        <Modal
          title={
            <div>
              <span>Assessment Result</span>
              <a
                href={`/survey/assessment/${record.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrinterFilled style={{ marginLeft: '10px' }} />
              </a>
            </div>
          }
          visible={openModal}
          onCancel={setOpenModal.bind(null, false)}
          footer={null}
        >
          <Row gutter={32}>
            <Col md={24}>
              <Survey
                model={form}
                mode="display"
                data={assessmentFix(record.data)}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};
const ClearButton = ({ touchId }: { touchId: string }) => {
  const history = useHistory();
  const [touchpoint] = useFetchLoad(
    api.touchpoint.getOne,
    [touchId],
    [touchId]
  );
  return (
    <span>
      <span>({touchpoint && touchpoint.name})</span>
      <span
        onClick={() => {
          history.push('/reports/assessment');
        }}
        style={{
          color: '#1890ff',
          fontSize: '0.7em',
          marginLeft: '5px',
          cursor: 'pointer'
        }}
      >
        clear
      </span>
    </span>
  );
};

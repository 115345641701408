import React from 'react';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { Col, Row, Space, Tag, Tooltip, Typography } from 'antd';
import {
  StatisticCard,
  StatisticValue
} from '../../../components/DetailStatistic';
import api from '../../../services/api/api';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import AssessmentMonthly from './AssessmentMonthly';
import { getYear } from 'date-fns';
import moment from 'moment';
import FormResultMonthly from './FormResultMonthly';
import ServiceRequestMonthly from './ServiceRequestMonthly';
import { pipe } from 'fp-ts/lib/function';
import apifns from '../../../services/api/api.functions';
import Summary from './Summary';
import { FilterOutlined } from '@ant-design/icons';
import { roleCheck } from '../../../services/auth';
import { OrganizationSelect } from '../../../components/OrganizationSelect';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import { FacilitySelect } from '../../../components/FacilitySelect';

export const momentYear = (year: number) => moment(new Date(year, 1, 1));

export const Dashboard = () => {
  const [facility] = useFetchLoad(api.facility.statistic, []);
  const [touchpoint] = useFetchLoad(api.touchpoint.statistic, []);
  const [serviceRequest] = useFetchLoad(api.serviceRequest.statistic, []);
  const [assessment] = useFetchLoad(api.assessment.statistic, []);
  const [formResults] = useFetchLoad(api.formResults.statistic, []);

  const [formResultsDay] = useFetchLoad(
    () =>
      pipe(
        api.formResults.dailyStatistic(),
        apifns.singleFilter('date', new Date().toISOString())
      ),
    []
  );
  const [asssesmentsDay] = useFetchLoad(
    () =>
      pipe(
        api.assessment.dailyStatistic(),
        apifns.singleFilter('date', new Date().toISOString())
      ),
    []
  );
  const [serrviceRequestsDay] = useFetchLoad(
    () =>
      pipe(
        api.serviceRequest.dailyStatistic(),
        apifns.singleFilter('date', new Date().toISOString())
      ),
    []
  );

  const getnormalYear = (date: string) => getYear(Date.parse(date));

  return (
    <BaseAdmin selectedMenu={['dashboard']}>
      <div id="dashboard">
        <div className="statistic">
          <Formik
            initialValues={{
              year: new Date().toISOString(),
              organizations: [],
              facilities: []
            }}
            onSubmit={() => {}}
            render={formikProps => {
              const { values } = formikProps;
              const { year, organizations, facilities } = values;

              return (
                <Form>
                  <div
                    style={{
                      position: 'sticky',
                      top: '0px',
                      zIndex: 10
                    }}
                  >
                    <Row
                      style={{
                        backgroundColor: 'white',
                        borderBottom: '1px black solid',
                        height: '70px',
                        marginBottom: '40px',
                        paddingLeft: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '-20px',
                        marginRight: '-20px'
                      }}
                      gutter={50}
                    >
                      <Col>
                        <Space size={10}>
                          <FilterOutlined />
                          <Typography.Text strong>Filters</Typography.Text>
                        </Space>
                      </Col>

                      <Col>
                        <Space>
                          {'Year'}
                          <DatePicker
                            name="year"
                            clearIcon={false}
                            picker="year"
                          />
                        </Space>
                      </Col>
                      {roleCheck('admin') && (
                        <Col>
                          <Space>
                            {'Organizations'}
                            <OrganizationSelect name="organizations" multiple />
                          </Space>
                        </Col>
                      )}
                      <Col>
                        <Space>
                          {'Facilities'}
                          <FacilitySelect
                            name="facilities"
                            organizationIds={organizations}
                            multiple
                            disabled={
                              roleCheck('admin') && organizations.length === 0
                            }
                          />
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Row gutter={[24, 24]}>
                    <Col lg={7} md={12} xs={24}>
                      <StatisticCard title="Facilities">
                        <StatisticValue
                          title="Active"
                          value={facility?.active}
                        />
                        <StatisticValue
                          title="Deactive"
                          value={facility?.deactive}
                        />
                      </StatisticCard>
                    </Col>
                    <Col lg={7} md={12} xs={24}>
                      <StatisticCard title="Touchpoints">
                        <StatisticValue
                          title="Active"
                          value={touchpoint?.active}
                        />
                        <StatisticValue
                          title="Deactive"
                          value={touchpoint?.deactive}
                        />
                      </StatisticCard>
                    </Col>
                    <Col lg={10} md={24} xs={24}>
                      <StatisticCard title="Reports">
                        <StatisticValue
                          title="Form Results"
                          value={formResults?.active}
                          extra={
                            formResultsDay &&
                            formResultsDay.active > 0 && (
                              <Tooltip title="Today">
                                <Tag color="green">{`+${formResultsDay.active}`}</Tag>
                              </Tooltip>
                            )
                          }
                        />
                        <StatisticValue
                          title="Service Request"
                          value={serviceRequest?.active}
                          extra={
                            serrviceRequestsDay &&
                            serrviceRequestsDay.active > 0 && (
                              <Tooltip title="Today">
                                <Tag color="green">{`+${serrviceRequestsDay.active}`}</Tag>
                              </Tooltip>
                            )
                          }
                        />
                        <StatisticValue
                          title="Assessment"
                          value={assessment?.active}
                          extra={
                            asssesmentsDay &&
                            asssesmentsDay.active > 0 && (
                              <Tooltip title="Today">
                                <Tag color="green">{`+${asssesmentsDay.active}`}</Tag>
                              </Tooltip>
                            )
                          }
                        />
                      </StatisticCard>
                    </Col>
                    <Col sm={24} xs={24}>
                      <Summary
                        year={getnormalYear(year)}
                        organizations={organizations}
                        facilities={facilities}
                      />
                    </Col>
                    <Col sm={24} xs={24}>
                      <AssessmentMonthly
                        year={getnormalYear(year)}
                        organizations={organizations}
                        facilities={facilities}
                      />
                    </Col>
                    <Col sm={24} xs={24}>
                      <FormResultMonthly
                        year={getnormalYear(year)}
                        organizations={organizations}
                        facilities={facilities}
                      />
                    </Col>
                    <Col sm={24} xs={24}>
                      <ServiceRequestMonthly
                        year={getnormalYear(year)}
                        organizations={organizations}
                        facilities={facilities}
                      />
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </BaseAdmin>
  );
};

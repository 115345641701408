import React, { FC, SVGProps } from 'react';

import './MessageCard.scss';

interface MessageCardProps {
  title: string;
  description?: string;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  mail?: string;
}

const MessageCard: FC<MessageCardProps> = ({
  Icon,
  title,
  description,
  mail
}) => {
  return (
    <div className="message_card">
      {Icon && <Icon className="message_card__icon" />}
      <div className="message_card__title">{title}</div>
      <div className="message_card__description">{description}</div>
      {mail && <div className="message_card__mail">{mail}</div>}
    </div>
  );
};

export default MessageCard;

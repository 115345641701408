import React, { useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useHistory,
  useLocation
} from 'react-router-dom';
import {
  EditFilled,
  FileFilled,
  PlusOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import {
  Table,
  Divider,
  Card,
  Button,
  Typography,
  Switch,
  Pagination,
  Tooltip,
  Space
} from 'antd';
import { siteUrl } from '../../../services/request';
import QRCode from '../../../utils/qr-code.component';
import { roleCheck } from '../../../services/auth';
import api from '../../../services/api/api';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import useLock from '../../../hooks/useLock';
import apifns from '../../../services/api/api.functions';
import { useList } from '../../../hooks/useList';
import { pipe } from 'fp-ts/lib/function';

const { Column } = Table;

export function TouchpointsList(
  props: RouteComponentProps & { match: { params: { facId: string } } }
) {
  const [facId, setFacId] = useState<undefined | string | null>();
  const loc = useLocation();

  const lock = useLock(() => {
    const x = new URLSearchParams(loc.search).get('facId');
    if (x === null || x === undefined) {
      setFacId(undefined);
    } else {
      setFacId(x);
    }
  }, []);

  const { data, loading, paginationConfig, onTableChange } = useList(
    () =>
      pipe(
        api.touchpoint.getAll(),
        apifns.arrayFilter('facilityIds', facId ? parseInt(facId) : undefined)
      ),
    [lock],
    [facId]
  );

  const { match } = props;
  const [facility] = useFetchLoad(api.facility.getOne, [facId], [facId]);
  const history = useHistory();

  return (
    <Card
      className="items-list-table"
      title={
        <>
          {'Touchpoints'}
          {facId && (
            <span>
              <span>({facility && facility.name})</span>
              <span
                onClick={() => {
                  history.push('/touchpoints');
                  setFacId(undefined);
                }}
                style={{
                  color: '#1890ff',
                  fontSize: '0.7em',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
              >
                clear
              </span>
            </span>
          )}
        </>
      }
      extra={
        roleCheck('admin', 'manager') && (
          <Link to={`${match.url}/create`}>
            <Button type="primary" size="small">
              <PlusOutlined />
            </Button>
          </Link>
        )
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        onChange={onTableChange}
        pagination={false}
        loading={loading}
      >
        <Column title="Touchpoint Name" dataIndex="name" sorter key="name" />
        <Column
          title="Facility"
          dataIndex={['facility', 'name']}
          width="20%"
          sorter
          key="facility"
        />
        <Column title="Token" dataIndex="token" />
        <Column
          title="Status"
          width="5%"
          render={(record: any) => (
            <Switch checked={record.status} size="small" />
          )}
        />
        <Column
          title="Action"
          width="35%"
          render={(record: any) => {
            return (
              <span>
                <Typography.Text
                  copyable={{
                    text: `${siteUrl}/report/${record.token}`,
                    tooltips: 'copy'
                  }}
                >
                  Touchpoint link
                </Typography.Text>
                <Divider type="vertical" />
                <QRCode
                  value={`${siteUrl}/report/${record.token}`}
                  name={`${record.name}.${record.token}`}
                >
                  <Tooltip title="Download QR">
                    <Space>
                      <QrcodeOutlined />
                      <span> QR</span>
                    </Space>
                  </Tooltip>
                </QRCode>
                <Divider type="vertical" />
                <Link to={`reports/serviceRequest?touchId=${record.id}`}>
                  <Tooltip title="See Service Requests">
                    <Space>
                      <FileFilled />
                      <span> Service</span>
                    </Space>
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                <Link to={`reports/assessment?touchId=${record.id}`}>
                  <Tooltip title="See Assessment Results">
                    <Space>
                      <FileFilled />
                      <span> Assessment</span>
                    </Space>
                  </Tooltip>
                </Link>
                <Divider type="vertical" />
                {roleCheck('admin', 'manager') && (
                  <Link to={`${match.url}/${record.id}/edit`}>
                    <Button>
                      <Space>
                        <EditFilled />
                        <span>Edit</span>
                      </Space>
                    </Button>
                  </Link>
                )}
              </span>
            );
          }}
        />
      </Table>
      <Pagination
        style={{
          padding: '20px'
        }}
        {...paginationConfig}
      />
    </Card>
  );
}

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Survey } from 'survey-react';
import 'survey-react/survey.css';
import api from '../../../../services/api/api';
import { assessmentFix } from '../../../../utils/helper';
import { useFetchLoad } from '../../../../utils/useFetchLoad';
import assessment1 from '../../../admin/reports/assessment-forms/assessment1';
import assessment2 from '../../../admin/reports/assessment-forms/assessment2';

const ResultAssessmentSurvey = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const [assessment] = useFetchLoad(
    api.assessment.getOne,
    [assessmentId],
    [assessmentId]
  );

  const [result, setResult] = useState<any>();

  useEffect(() => {
    if (assessment) {
      setResult(assessmentFix(assessment.data));
    }
  }, [assessment]);

  const form = assessment
    ? assessment.formId === 2
      ? assessment2
      : assessment1
    : assessment1;

  return (
    <div>
      {assessment && (
        <Survey model={form} data={result} isSinglePage mode="display" />
      )}
    </div>
  );
};

export default ResultAssessmentSurvey;

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  EditFilled,
  EyeFilled,
  InfoCircleFilled,
  PlusOutlined
} from '@ant-design/icons';
import { Table, Divider, Card, Button, Space } from 'antd';
import { roleCheck } from '../../../services/auth';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import api from '../../../services/api/api';
import { FormType } from '../../../services/api/Form/form.type';
import { compareStringg, compareString } from '../../../utils/helper';

const { Column } = Table;

const FormsList = (props: RouteComponentProps) => {
  const [data, loading] = useFetchLoad(api.form.getAll, [], []);
  const {
    match: { url }
  } = props;

  return (
    <Card
      className="items-list-table"
      title="Forms"
      extra={
        roleCheck('admin', 'manager') && (
          <Link to={`${url}/create`}>
            <Button type="primary" size="small">
              <PlusOutlined />
            </Button>
          </Link>
        )
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total: any) => `Total ${total} items`,
          size: 'small'
        }}
        loading={loading}
      >
        <Column
          title="Title"
          dataIndex="title"
          sorter={compareStringg<FormType>('title')}
        />
        <Column
          title="Description"
          dataIndex="description"
          sorter={compareStringg<FormType>('description')}
          width="50%"
        />
        <Column
          title="Organization"
          dataIndex={['organization', 'name']}
          sorter={(a: FormType, b: FormType) =>
            a.organization && b.organization
              ? compareString(a.organization.name, b.organization.name)
              : a.organization
              ? -1
              : 1
          }
        />
        <Column
          title="Preview"
          render={(record: FormType) => {
            return (
              <span>
                <a
                  href={`/survey/test/${record.token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EyeFilled />
                </a>
              </span>
            );
          }}
        />
        <Column
          title="Action"
          width="20%"
          render={(record: FormType) => {
            return (
              <span>
                <Link to={`/form-results?formId=${record.token}`}>
                  <InfoCircleFilled style={{ marginRight: '10px' }} />
                  <span>Results</span>
                </Link>
                <Divider type="vertical" />
                {roleCheck('admin', 'manager') && (
                  <Link to={`${url}/${record.id}/edit`}>
                    <Button>
                      <Space>
                        <EditFilled />
                        <span>Edit</span>
                      </Space>
                    </Button>
                  </Link>
                )}
              </span>
            );
          }}
        />
      </Table>
    </Card>
  );
};

export default FormsList;

import React, { FunctionComponent } from 'react';
import { StepForm, Step } from '../../../components/StepForm';
import * as yup from 'yup';

import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';

import { Card } from 'antd';
import { FormControllerProps } from '../../../utils/globalTypes';
import { useParams, useHistory } from 'react-router';
import { message } from 'antd';
import {
  emptyForm,
  formBinder,
  FormFormType
} from '../../../services/api/Form/form.type';
import FormDataForm from './forms/FormDataForm';

export const EditForm = () => {
  const { formId } = useParams<any>();
  const [form] = useFetchLoad(api.form.getOne, [formId], [formId]);

  return (
    <FormFormController
      type="edit"
      initialValues={formBinder(form ? form : emptyForm)}
      submitFn={(data, history) => {
        delete data.organizationId;

        data.form = JSON.parse(data.form);
        return foldApi(api.form.updateOne(formId, data))
          .then(() => {
            message.success('form updated successfully.');
            history.push('/forms');
          })
          .catch(err => message.error('Something went wrong!'));
      }}
    />
  );
};

export const CreatForm = () => (
  <FormFormController
    type="create"
    initialValues={formBinder(emptyForm)}
    submitFn={(data, history) => {
      data.form = JSON.parse(data.form);
      return foldApi(api.form.creatOne(data))
        .then(() => {
          message.success('form created successfully.');
          history.push('/forms');
        })
        .catch(err => {
          message.error('Something went wrong!');
        });
    }}
  />
);

const FormFormController: FunctionComponent<
  FormControllerProps<FormFormType>
> = ({ type, submitFn, initialValues }) => {
  const history = useHistory();
  const FormDataFormSchema = yup.object().shape({
    title: yup.string().min(3).required(),
    description: yup.string().min(3).required(),
    form: yup.string().min(3)
  });

  return (
    <Card>
      <StepForm<FormFormType>
        initialValues={initialValues}
        onSubmit={async x => {
          await submitFn(x, history);
        }}
        editMode={type === 'edit'}
        cancelHref={'/forms'}
      >
        <Step title="Form Information" validationSchema={FormDataFormSchema}>
          <FormDataForm />
        </Step>
      </StepForm>
    </Card>
  );
};

import React, { FunctionComponent } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input } from 'formik-antd';
import { Row, Col, Button } from 'antd';

import { FieldArray } from 'formik';
import { Typography } from 'antd';
const { Title } = Typography;

// tslint:disable-next-line:interface-name
interface EmailListProps {
  emails?: string[];
  name?: string;
  label: string;
}

export const EmailListInput: FunctionComponent<EmailListProps> = ({
  emails = [],
  name = 'emails',
  label
}) => {
  return (
    <div>
      <Row>
        <Col sm={24}>
          <Title level={5}>{label}</Title>
        </Col>
        <Col span={24}>
          <FieldArray
            name={name}
            render={arrayHelpers => (
              <>
                {emails.map((link: any, i: number) => (
                  // tslint:disable-next-line: jsx-key
                  <Row gutter={24}>
                    <Col sm={22}>
                      <Form.Item name={`${name}[${i}]`}>
                        <Input name={`${name}[${i}]`} />
                      </Form.Item>
                    </Col>
                    <Col sm={2}>
                      <Row>
                        <Col sm={12}>
                          <DeleteOutlined
                            onClick={() => {
                              arrayHelpers.remove(i);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    arrayHelpers.push('');
                  }}
                  block
                >
                  <PlusOutlined /> Add field
                </Button>
              </>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

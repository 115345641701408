import { getRequest } from '../services/request';
import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';

export function useGet(url: string, errorMessage?: string) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    setLoading(true);
    const request = getRequest();
    request({
      method: 'GET',
      url
    })
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch(err => {
        message.error(errorMessage || 'Could not get list', 5);
      });
  }, [url, setLoading, setData, errorMessage]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data, loading, reload: fetch };
}

import React, { useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useHistory,
  useLocation
} from 'react-router-dom';
import { ApiFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Table, Divider, Card, Button, Switch, Pagination, Space } from 'antd';
import { roleCheck } from '../../../services/auth';
import api from '../../../services/api/api';
import { useFetchLoad } from '../../../utils/useFetchLoad';
import useLock from '../../../hooks/useLock';
import { useList } from '../../../hooks/useList';
import { pipe } from 'fp-ts/lib/function';
import apifns from '../../../services/api/api.functions';

const { Column } = Table;

export function FacilitiesList(
  props: RouteComponentProps & { match: { params: { orgId: string } } }
) {
  const { match } = props;
  const loc = useLocation();
  const history = useHistory();
  const [orgId, setOrgId] = useState<string | undefined>();

  const lock = useLock(() => {
    const x = new URLSearchParams(loc.search).get('orgId');
    if (x === null || x === undefined) {
      setOrgId(undefined);
    } else {
      setOrgId(x);
    }
  }, []);

  const { data, loading, paginationConfig, onTableChange } = useList(
    () =>
      pipe(
        api.facility.getAll(),
        apifns.arrayFilter(
          'organizationIds',
          orgId ? parseInt(orgId) : undefined
        )
      ),
    [lock],
    [orgId]
  );

  const [organization] = useFetchLoad(
    api.organization.getOne,
    [orgId],
    [orgId]
  );

  return (
    <Card
      className="items-list-table"
      title={
        <>
          {'Facilities'}
          {orgId && (
            <span>
              <span>({organization && organization.name})</span>
              <span
                onClick={() => {
                  history.push('/facilities');
                  setOrgId(undefined);
                }}
                style={{
                  color: '#1890ff',
                  fontSize: '0.7em',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
              >
                clear
              </span>
            </span>
          )}
        </>
      }
      extra={
        <>
          {roleCheck('manager', 'admin') && (
            <Link to={`${match.url}/create`}>
              <Button type="primary" size="small">
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </>
      }
    >
      <Table
        rowKey={(record: any) => record.id}
        dataSource={data}
        loading={loading}
        pagination={false}
        onChange={onTableChange}
      >
        <Column title="Name" dataIndex="name" key="name" sorter />
        <Column title="City" dataIndex="city" key="city" sorter />
        <Column title="State" dataIndex="state" />
        <Column title="Address" dataIndex="addressStreet" />
        <Column
          title="Organization"
          dataIndex={['organization', 'name']}
          width="20%"
          key="organization"
          sorter
        />
        <Column
          title="Status"
          width="5%"
          render={(record: any) => (
            <Switch checked={record.status} size="small" />
          )}
        />
        <Column
          title="Action"
          width="20%"
          render={(record: any) => {
            return (
              <span>
                <Link to={`/touchpoints?facId=${record.id}`}>
                  <ApiFilled />
                  <span> Touchpoints</span>
                </Link>
                <Divider type="vertical" />
                {roleCheck('admin', 'manager', 'branchManager') && (
                  <Link to={`${match.url}/${record.id}/edit`}>
                    <Button>
                      <Space>
                        <EditFilled />
                        <span>Edit</span>
                      </Space>
                    </Button>
                  </Link>
                )}
              </span>
            );
          }}
        />
      </Table>
      <Pagination
        style={{
          padding: '20px'
        }}
        {...paginationConfig}
      />
    </Card>
  );
}

import React, { FunctionComponent } from 'react';
import { StepForm, Step } from '../../../components/StepForm';
import * as yup from 'yup';

import { useFetchLoad } from '../../../utils/useFetchLoad';
import api, { foldApi } from '../../../services/api/api';
import {
  OrganizationFormType,
  emptyOrganization,
  organizationBinder
} from '../../../services/api/Organization/organization.model';
import { Card } from 'antd';
import { FormControllerProps } from '../../../utils/globalTypes';
import { useParams, useHistory } from 'react-router';
import { message } from 'antd';
import { OrganizationInformationForm } from './forms/OrganizationInformationForm';
import { OrganizationEmailInformationForm } from './forms/OrganizationEmailInformationForm';
import { OrganizationReportPageForm } from './forms/OrganizationReportPageForm';
import { zipcodeValidation, phoneValidation } from '../../../utils/helper';

export const EditOrganization = () => {
  const { orgId } = useParams<any>();
  const [organization] = useFetchLoad(
    api.organization.getOne,
    [orgId],
    [orgId]
  );
  return (
    <OrganizationFormController
      type="edit"
      initialValues={organizationBinder(
        organization ? organization : emptyOrganization
      )}
      submitFn={(data, history) =>
        foldApi(api.organization.updateOne(orgId, data))
          .then(() => {
            message.success('organization updated successfully.');
            history.push('/organizations');
          })
          .catch(err => message.error('Something went wrong!'))
      }
    />
  );
};

export const CreatOrganization = () => (
  <OrganizationFormController
    type="create"
    initialValues={organizationBinder(emptyOrganization)}
    submitFn={(data, history) =>
      foldApi(api.organization.creatOne(data))
        .then(() => {
          message.success('organization created successfully.');
          history.push('/organizations');
        })
        .catch(err => {
          message.error('Something went wrong!');
        })
    }
  />
);

const OrganizationFormController: FunctionComponent<
  FormControllerProps<OrganizationFormType>
> = ({ type, submitFn, initialValues }) => {
  const history = useHistory();
  const OrganizationInformationFormSchema = yup.object().shape({
    name: yup.string().min(3).required(),
    phone: phoneValidation.nullable(),
    email: yup.string().email().nullable(),
    timezone: yup.string().required(),
    status: yup.boolean(),
    addressStreet: yup.string().required(),
    addressUnit: yup.string().nullable(),
    zipCode: zipcodeValidation,
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required()
  });

  const OrganizationEmailInformationFormSchema = yup.object().shape({
    emailSubject: yup.string().required(),
    emailTo: yup.string().email().required(),
    emailFrom: yup.string().email(),
    ccEmails: yup.array(
      yup
        .string()
        .email('enter a valid mail.')
        .required('this is a requierd field.')
    ),
    bccEmails: yup.array(
      yup
        .string()
        .email('enter a valid mail.')
        .required('this is a requierd field.')
    )
  });

  const OrganizationReportPageFormSchema = yup.object().shape({
    logo: yup.string().nullable(),
    reportPageTitle: yup.string().required(),
    reportPageLiveChat: yup.string()
  });

  return (
    <Card>
      <StepForm<OrganizationFormType>
        initialValues={initialValues}
        onSubmit={async x => {
          await submitFn(x, history);
        }}
        editMode={type === 'edit'}
        cancelHref={'/organizations'}
      >
        <Step
          title="Organization Information"
          validationSchema={OrganizationInformationFormSchema}
        >
          <OrganizationInformationForm />
        </Step>
        <Step
          title="Email Information"
          validationSchema={OrganizationEmailInformationFormSchema}
        >
          <OrganizationEmailInformationForm />
        </Step>
        <Step
          title="Service Request Setting"
          validationSchema={OrganizationReportPageFormSchema}
        >
          <OrganizationReportPageForm />
        </Step>
      </StepForm>
    </Card>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import {
  ApiOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  FileOutlined,
  FormOutlined,
  ToolOutlined,
  UserOutlined
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';
import { hasRole } from '../../../services/auth';
import SubMenu from 'antd/lib/menu/SubMenu';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';

export const SideMenu = (props: { selectedKeys: string[] }) => (
  <Layout.Sider
    breakpoint="md"
    collapsedWidth="0"
    collapsible
    zeroWidthTriggerStyle={{
      zIndex: 100
    }}
    style={{
      paddingTop: '7px'
    }}
  >
    <Logo
      style={{
        padding: '5px 0px 2px 0px',
        width: '100%',
        height: '30px',
        margin: '10px 0px'
      }}
    />
    <Menu theme="dark" mode="inline" selectedKeys={props.selectedKeys}>
      <Menu.Item key="dashboard">
        <Link to="/">
          <AppstoreOutlined />
          <span className="nav-text">Dashboard</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="organizations">
        <Link to="/organizations">
          <DatabaseOutlined />
          <span className="nav-text">Organizations</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="facilities">
        <Link to="/facilities">
          <ToolOutlined />
          <span className="nav-text">Facilities</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="touchpoints">
        <Link to="/touchpoints">
          <ApiOutlined />
          <span className="nav-text">Touchpoints</span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="reports"
        title={
          <span>
            <FileOutlined />
            <span>Reports</span>
          </span>
        }
      >
        <Menu.Item key="serviceRequest_reports">
          <Link to="/reports/serviceRequest">
            <span className="nav-text">Service Request</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="assessment_reports">
          <Link to="/reports/assessment">
            <span className="nav-text">Assessment</span>
          </Link>
        </Menu.Item>
        {(hasRole('admin') ||
          hasRole('manager') ||
          hasRole('branchManager') ||
          hasRole('monitor')) && (
          <Menu.Item key="form-results">
            <Link to="/form-results">
              <span className="nav-text">Form Results</span>
            </Link>
          </Menu.Item>
        )}
      </SubMenu>

      {(hasRole('admin') || hasRole('manager') || hasRole('branchManager')) && (
        <Menu.Item key="forms">
          <Link to="/forms">
            <FormOutlined />
            <span className="nav-text">Forms</span>
          </Link>
        </Menu.Item>
      )}

      {(hasRole('admin') || hasRole('manager') || hasRole('branchManager')) && (
        <Menu.Item key="users">
          <Link to="/users">
            <UserOutlined />
            <span className="nav-text">Users</span>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  </Layout.Sider>
);

import { makeRequest, methods } from '../api';
import { TouchpointType, TouchpointFormType } from './touchpoint.model';

const url = '/touchpoints';

const getAll = () => {
  return makeRequest<TouchpointType[]>(url, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<TouchpointType>(`${url}/${id}`, methods.get);
};

const deleteOne = (id: string) => {
  return makeRequest(`${url}/${id}`, methods.delete);
};

const creatOne = (data: TouchpointFormType) => {
  return makeRequest(`${url}`, methods.post, data);
};

const updateOne = (id: string, data: Partial<TouchpointFormType>) => {
  return makeRequest(`${url}/${id}`, methods.put, data);
};

const statistic = () => {
  return makeRequest<{ total: number; active: number; deactive: number }>(
    `${url}/other/statistic`,
    methods.get
  );
};

const touchApi = {
  getAll,
  getOne,
  deleteOne,
  creatOne,
  updateOne,
  statistic
};

export default touchApi;

import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { FacilitiesList } from './FacilitiesList';
import { TouchpointsList } from '../touchpoints/TouchpointsList';
import { ReportsList } from '../reports/ServiceRequestReportsList';
import { CreatFacility, EditFacility } from './FacilityFormController';
import {
  EditTouchpoint,
  CreatTouchpoint
} from '../touchpoints/TouchpointFormController';

export const Facilities = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['facilities']}>
      <Route
        exact
        path={`${path}/:facId/touchpoints/:touchId/reports`}
        component={ReportsList}
      />
      <Route
        exact
        path={`${path}/:facId/touchpoints/:touchId/edit`}
        component={EditTouchpoint}
      />
      <Route
        exact
        path={`${path}/:facId/touchpoints/create`}
        component={CreatTouchpoint}
      />
      <Route
        exact
        path={`${path}/:facId/touchpoints`}
        component={TouchpointsList}
      />
      <Route exact path={path} component={FacilitiesList} />
      <Route exact path={`${path}/:facId/edit`} component={EditFacility} />
      <Route exact path={`${path}/create`} component={CreatFacility} />
    </BaseAdmin>
  );
};

import { Column } from '@ant-design/charts';
import { Card } from 'antd';
import { pipe } from 'fp-ts/lib/function';
import React, { FC } from 'react';
import api from '../../../services/api/api';
import apifns from '../../../services/api/api.functions';
import { useFetchLoad } from '../../../utils/useFetchLoad';

export const months = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

interface AssessmentMonthlyProps {
  year: number;
  organizations: number[];
  facilities: number[];
}

const AssessmentMonthly: FC<AssessmentMonthlyProps> = ({
  year,
  organizations,
  facilities
}) => {
  const [assessmentMonthly, loading] = useFetchLoad(
    () =>
      pipe(
        api.assessment.monthlyStatistic(),
        apifns.singleFilter('year', year.toString()),
        organizations.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('organizationIds', ...organizations),
        facilities.length === 0
          ? apifns.empty()
          : apifns.arrayFilter('facilityIds', ...facilities)
      ),
    [year, organizations, facilities],
    [year, organizations, facilities]
  );

  const data =
    assessmentMonthly &&
    assessmentMonthly.reduce(
      (acu: any, curr: any) => [
        ...acu,
        curr && {
          month: months[curr.month],
          count: curr.certified > 0 ? curr.certified : undefined,
          type: 'Certified'
        },
        {
          month: months[curr.month],
          count: curr.notCertified > 0 ? curr.notCertified : undefined,
          type: 'Not Certified'
        }
      ],
      []
    );
  return (
    <Card title="Assessment">
      {
        <Column
          data={data ?? []}
          loading={loading}
          isStack={true}
          height={300}
          legend={{
            position: 'top-left',
            padding: [5, 0, 25, 0]
          }}
          xField="month"
          yField="count"
          seriesField="type"
          color={['#61DDAA', '#65789B']}
          label={{
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' }
            ]
          }}
        />
      }
    </Card>
  );
};

export default AssessmentMonthly;

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Input from '../../../components/Input/Input';
import Submit from '../../../components/Submit/Submit';
import './Login.scss';
import { Link, useHistory } from 'react-router-dom';
import { setData, setToken } from '../../../services/auth';
import Outer from '../../OuterPages/Outer';
import api from '../../../services/api/api';
import { message } from 'antd';

const Login = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Outer title="Login" goTo={{ title: 'signup', url: '/signup' }}>
        <div className="login__content">
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={values => {
              const { username, password } = values;
              setLoading(true);
              api.auth
                .login(username, password)
                .then(res => {
                  if (res.access_token) {
                    setToken(res.access_token);
                    setData(username, res.role);
                    setLoading(false);
                    message.success('welcome');
                    history.push('/');
                  }
                })
                .catch(err => {
                  const data = err.response.data;
                  setLoading(false);
                  if (data.code === 2) {
                    history.push('/login/verify', {
                      email: username
                    });
                  } else {
                    message.error('Email or password is wrong!');
                  }
                });
            }}
            render={formikBag => (
              <Form>
                <Input
                  name="username"
                  title="Email"
                  placeholder="joe@example.com"
                  type="text"
                />
                <Input
                  name="password"
                  title="Password"
                  type="password"
                  placeholder="Password"
                />
                <Submit loading={loading} />
              </Form>
            )}
          />
          <span className="login__content__sub">
            <Link to="forgot">Forgot your password?</Link>
          </span>
        </div>
      </Outer>
    </>
  );
};

export default Login;

import { makeRequest, methods } from '../api';
import { UserType, UserFormType } from './user.model';

const url = '/users';

const getAll = (...organizations: number[]) => {
  const params =
    organizations.length > 0 ? `?organizationIds=[${organizations}]` : '';
  return makeRequest<UserType[]>(url + params, methods.get);
};

const getOne = (id: number) => {
  return makeRequest<UserType>(`${url}/${id}`, methods.get);
};

const deleteOne = (id: string) => {
  return makeRequest(`${url}/${id}`, methods.delete);
};
const creatOne = (data: UserFormType) => {
  return makeRequest(`${url}`, methods.post, data);
};

const updateOne = (id: string, data: Partial<UserFormType>) => {
  return makeRequest(`${url}/${id}`, methods.put, data);
};

const userApi = {
  getAll,
  getOne,
  deleteOne,
  creatOne,
  updateOne
};

export default userApi;

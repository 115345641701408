import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Divider } from 'antd';
import { Input, Switch, Form } from 'formik-antd';
import { TimezoneSelect } from '../../../../components/TimezoneSelect';
import { hasRole } from '../../../../services/auth';

export function OrganizationInformationForm(props: any) {
  return (
    <div>
      <Row gutter={24}>
        {(hasRole('admin') || hasRole('manager')) && (
          <Col sm={8} xs={24}>
            <Form.Item name="name" label="Organization name" required>
              <Input name="name" placeholder="organization name" />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={8} xs={24}>
          <Form.Item name="timezone" label="Timezone" required>
            <TimezoneSelect name="timezone" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {hasRole('admin') && (
          <>
            <Col sm={3} xs={24}>
              <Form.Item name="status" label="Status">
                <Switch name="status" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col sm={8} xs={24}>
          <Form.Item name="phone" label="Phone">
            <Input name="phone" placeholder="phone number" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={8} xs={24}>
          <Form.Item name="email" label="Email">
            <Input name="email" placeholder="email" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Address</Divider>
      <Row gutter={24}>
        <Col sm={18} xs={24}>
          <Form.Item name="addressStreet" label="Street address" required>
            <Input name="addressStreet" placeholder="street address" />
          </Form.Item>
        </Col>
        <Col sm={6} xs={24}>
          <Form.Item name="addressUnit" label="Unit/Apt">
            <Input name="addressUnit" placeholder="unit/apt" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="zipCode" label="Zip code/Postal code" required>
            <Input name="zipCode" placeholder="Zip code/Postal code" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="city" label="City" required>
            <Input name="city" placeholder="city" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="state" label="State/Province" required>
            <Input name="state" placeholder="state/province" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={24}>
          <Form.Item name="country" label="Country" required>
            <Input name="country" placeholder="Country" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

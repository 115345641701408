import { OrganizationType } from '../Organization/organization.model';

export interface FormType {
  id: number;
  title: string;
  organizationId?: number;
  organization?: OrganizationType;
  token: string;
  description: string;
  form: object;
}

export interface FormFormType {
  title: string;
  organizationId?: number;
  description: string;
  form: string;
}

export const emptyForm: FormType = {
  id: 0,
  title: '',
  organizationId: undefined,
  token: '',
  description: '',
  form: {}
};

export const formBinder = ({
  title,
  organizationId,
  description,
  form
}: FormType): FormFormType => ({
  title,
  organizationId,
  description,
  form: JSON.stringify(form)
});

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import organization from './Organization/organization.api';
import user from './User/user.api';
import touchpoint from './Touchpoint/touchpoint.api';
import facility from './Facility/facility.api';
import form from './Form/form.api';
import formResults from './FormResults/formResults.api';
import assessment from './AssessmentReport/assessment.api';
import serviceRequest from './ServiceReport/serviceReport.api';
import auth from './Auth/auth.api';
import { axiosInterceptors } from '../refreshToken';

export const BASE = '/api';

export enum methods {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete'
}
interface QueryType {
  [key: string]: string;
}

export interface REQUEST<RES, REQ = any> {
  url: string;
  method: methods;
  data?: REQ;
  queries: QueryType;
  headers: Record<string, string>;
  response?: RES;
}

export const makeRequest = <RES, REQ = any>(
  url: string,
  method: methods,
  data?: REQ,
  params: {} = {},
  headers: {} = {}
): REQUEST<RES, REQ> => ({
  url: url,
  method: method,
  data: data,
  queries: {},
  headers: {},
  response: undefined
});

export const foldApi = <RES, REQ = any>(req: REQUEST<RES, REQ>) => {
  return axios
    .request({
      url: `${BASE}${req.url}`,
      method: req.method,
      data: req.data,
      params: req.queries,
      headers: req.headers
    })
    .then((res: AxiosResponse) => {
      return {
        data: res.data,
        headers: res.headers
      };
    })
    .catch();
};

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.data && !(config.data instanceof FormData))
      config.data = checkObj(config.data);
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  axiosInterceptors(axios).response.ok,
  axiosInterceptors(axios).response.error
);

axios.interceptors.request.use(
  axiosInterceptors(axios).request.ok,
  axiosInterceptors(axios).request.error
);

const checkObj = (inObj: any): any => {
  const obj = inObj;
  return Array.isArray(obj)
    ? obj.map(x => {
        if (x === null || (typeof x === 'string' && x === '')) {
          return null;
        } else if (typeof x === 'object') {
          return checkObj(x);
        }
        return x;
      })
    : Object.keys(obj).reduce<object>((aqu, x) => {
        if (obj[x] === null || (typeof obj[x] === 'string' && obj[x] === '')) {
          return { ...aqu, [x]: null };
        } else if (typeof obj[x] === 'object') {
          return { ...aqu, [x]: checkObj(obj[x]) };
        }
        return { ...aqu, [x]: obj[x] };
      }, {});
};

const api = {
  organization,
  user,
  touchpoint,
  facility,
  form,
  assessment,
  serviceRequest,
  auth,
  formResults
};

export default api;

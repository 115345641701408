import React, { useEffect } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { Switch } from 'formik-antd';
import { Form, Input } from 'formik-antd';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { getRole } from '../../../../services/auth';

export function UserInformationForm(props: any) {
  const { values } = props;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('email', values.username);
  }, [values.username, setFieldValue]);

  const { userId } = useParams<any>();
  const type = userId ? 'edit' : 'create';

  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} xs={24}>
          <Form.Item name="firstName" label={'First name'}>
            <Input name="firstName" placeholder="First name" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item name="lastName" label={'Last name'}>
            <Input name="lastName" placeholder="Last name" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item name="username" label={'Email'}>
            <Input
              name="username"
              placeholder="email"
              disabled={type === 'edit' && getRole() !== 'admin'}
            />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24}>
          <Form.Item name="email" label={'Email'} style={{ display: 'none' }}>
            <Input name="email" placeholder="email" />
          </Form.Item>
        </Col>
        {type === 'create' && (
          <Col sm={24} xs={24}>
            <Form.Item name="password" label={'Password'}>
              <Input name="password" placeholder="password" type="password" />
            </Form.Item>
          </Col>
        )}
        <Col sm={24} xs={24}>
          <Form.Item name="isActive" label={'Active'}>
            <Switch name="isActive" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

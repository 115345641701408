import React from 'react';
import { Form, Select } from 'formik-antd';
import { Row, Col } from 'antd';
import { useFetchLoad } from '../../../../utils/useFetchLoad';
import api from '../../../../services/api/api';

export function FacilityUsersForm(props: any) {
  const { values } = props;

  const [users] = useFetchLoad(api.user.getAll, [[values.organizationId]], []);

  return (
    <div>
      <Row gutter={24}>
        <Col sm={24} xs={24}>
          <Form.Item name="userIds" label={'Users'}>
            <Select name="userIds" mode="multiple">
              {users &&
                users.map(user => (
                  <Select.Option value={user.id}>
                    {user.firstName + ' ' + user.lastName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

import { Model } from 'survey-react';

const form = {
  title: {
    default: 'Coronavirus Self Declaration Form',
    es: 'Formulario de autodeclaración de coronavirus'
  },
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'html',
          name: 'question13',
          html: {
            default:
              "<h5>For the health and safety of our community, declaration of illness is required. The following is a self-certification to help determine if you are clear to go to work or enter the facilities. It is intended to help track and stop the spread of COVID-19 and keep workplace, employees, and customers safe. </h5>\n<br>\n<h5>Be sure that the information you'll give is accurate and complete. Please get immediate medical attention if you have any of the COVID-19 signs. The results of this self-certification will solely be used to notify the employer/business if you are certified or not certified to go to work or enter this business. Your health information will not be shared and is confidential.</h5>\n<br>",
            es:
              '<h5> Para la salud y seguridad de nuestra comunidad, se requiere declaración de enfermedad. La siguiente es una autocertificación para ayudar a determinar si tiene permiso para ir a trabajar o ingresar a las instalaciones. Su objetivo es ayudar a rastrear y detener la propagación de COVID-19 y mantener seguros el lugar de trabajo, los empleados y los clientes. </h5>\n<br>\n<h5> Asegúrese de que la información que proporcione sea precisa y completa. Obtenga atención médica inmediata si tiene alguno de los signos de COVID-19. Los resultados de esta autocertificación se utilizarán únicamente para notificar al empleador / empresa si está certificado o no para ir a trabajar o ingresar a esta empresa. Su información de salud no se compartirá y es confidencial. </h5>\n<br>'
          }
        },
        {
          type: 'multipletext',
          name: 'name',
          title: {
            default: 'Name',
            es: 'Nombre'
          },
          hideNumber: true,
          isRequired: true,
          items: [
            {
              name: 'firstname',
              isRequired: true,
              title: {
                es: 'Nombre de pila',
                en: 'First name'
              }
            },
            {
              name: 'lastname',
              isRequired: true,
              title: {
                es: 'Apellido',
                en: 'Last name'
              }
            }
          ]
        },
        {
          type: 'text',
          name: 'email',
          title: {
            default: 'Email (optional)',
            es: 'Email (opcional)'
          },
          inputType: 'email'
        },
        {
          type: 'radiogroup',
          name: 'type',
          title: {
            default: 'Reason for declaration',
            es: 'Motivo de la declaración'
          },
          isRequired: true,
          choices: [
            {
              value: 'employee',
              text: {
                default: 'employee',
                es: 'personal'
              }
            },
            {
              value: 'visitor',
              text: {
                default: 'visitor',
                es: 'visitante'
              }
            },
            {
              value: 'customer',
              text: {
                es: 'cliente',
                default: 'customer'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'matrix',
          hasSelectAll: true,
          name: 'symptoms',
          title: {
            default:
              "Please state whether you've experienced (last 10 days) or are experiencing any of the following?",
            es:
              'Indique si ha experimentado (últimos 10 días) o está experimentando alguno de los siguientes.'
          },
          isRequired: true,
          columns: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          rows: [
            {
              value: 'Fever (temperature greater than 100.0 F)',
              text: {
                es: '(temperatura superior a 100. F)'
              }
            },
            {
              value: 'cough',
              text: {
                en: 'Cough',
                es: 'Tos'
              }
            },
            {
              value: 'Shortness of Breath',
              text: {
                es: 'Dificultad para respirar',
                en: 'Shortness of Breath'
              }
            },
            {
              value: 'Persistent Pain in the Chest',
              text: {
                en: 'Persistent Pain in the Chest',
                es: 'Dolor persistente en el pecho'
              }
            },
            {
              value: 'Blue lips or face',
              text: {
                es: 'Labios o cara azules',
                en: 'Blue lips or face'
              }
            },
            {
              value: 'Severe dizziness or feel lightheaded',
              text: {
                es: 'Mareos intensos o sensación de mareo',
                en: 'Severe dizziness or feel lightheaded'
              }
            },
            {
              value: 'Inability to lie down because of difficulty breathing',
              text: {
                es:
                  'Incapacidad para acostarse debido a la dificultad para respirar',
                en: 'Inability to lie down because of difficulty breathing'
              }
            }
          ],
          isAllRowRequired: true
        }
      ]
    },
    {
      name: 'page3',
      elements: [
        {
          type: 'matrix',
          name: 'symptoms2',
          title: {
            default: 'Do you have any of the following symptoms?',
            es: '¿Tiene alguno de los siguientes síntomas?'
          },
          isRequired: true,
          columns: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          rows: [
            {
              value: 'Chills (uncontrollable body shaking)',
              text: {
                es: 'Escalofríos (temblor incontrolable del cuerpo)',
                en: 'Chills (uncontrollable body shaking)'
              }
            },
            {
              value: 'Recent decrease in sense of smell or taste?',
              text: {
                es: '¿Disminución reciente del sentido del olfato o del gusto?',
                en: 'Recent decrease in sense of smell or taste?'
              }
            },
            {
              value: 'Conjunctivitis (pink eye)',
              text: {
                es: 'Conjuntivitis (conjuntivitis)',
                en: 'Conjunctivitis (pink eye)'
              }
            },
            {
              value: 'Runny or stuffy nose',
              text: {
                es: 'Nariz que moquea o tapada',
                en: 'Runny or stuffy nose'
              }
            },
            {
              value: 'Sore throat',
              text: {
                es: 'Dolor de garganta',
                en: 'Sore throat'
              }
            },
            {
              value: 'Muscle aches, body aches or headache',
              text: {
                es: 'Dolor muscular, dolor corporal o dolor de cabeza',
                en: 'Muscle aches, body aches or headache'
              }
            },
            {
              value: 'Tired / fatigued',
              text: {
                es: 'Cansada/Cansado - fatigada/fatigado',
                en: 'Tired / fatigued'
              }
            }
          ],
          isAllRowRequired: true
        }
      ]
    },
    {
      name: 'page4',
      elements: [
        {
          type: 'html',
          name: 'question6',
          html: {
            default: '<h4>Please answer following questions</h4>',
            es: '<h4>Por favor conteste las siguientes preguntas</h4>'
          }
        },
        {
          type: 'radiogroup',
          name: 'travel',
          title: {
            default:
              'Have you recently traveled to areas to be infected with COVID-19?',
            es: '¿Ha viajado recientemente a áreas infectadas con COVID-19?'
          },
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        },
        {
          type: 'radiogroup',
          name: 'contactInfected',
          title: {
            default:
              'Have you been in contact (less than 6 foot) with people being infected, suspected or diagnosed with COVID-19?',
            es:
              '¿Ha estado en contacto (menos de 6 pies) con personas infectadas, sospechosas o diagnosticadas con COVID-19?'
          },
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        },
        {
          type: 'radiogroup',
          name: 'caredForInfected',
          title: {
            default:
              'Have you recently (last 10 days) cared for someone who is / was ill?',
            es:
              '¿Ha cuidado recientemente (últimos 10 días) a alguien que está / estaba enfermo?'
          },
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ],
          colCount: 2
        }
      ]
    },
    {
      name: 'page5',
      elements: [
        {
          type: 'radiogroup',
          name: 'well',
          title: {
            default:
              'Do you feel well and are you able to perform your regular duties?',
            es:
              '¿Te sientes bien y eres capaz de realizar tus tareas habituales?'
          },
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: {
                default: 'Yes',
                es: 'Si'
              }
            },
            {
              value: 'false',
              text: 'No'
            }
          ]
        },
        {
          type: 'checkbox',
          name: 'acknowledge',
          titleLocation: 'hidden',
          isRequired: true,
          choices: [
            {
              value: 'true',
              text: {
                default:
                  "I acknowledge that the information I've given is accurate and complete.",
                es:
                  'Reconozco que la información que proporcioné es precisa y completa.'
              }
            }
          ]
        }
      ]
    }
  ],
  showCompletedPage: false,
  showProgressBar: 'bottom',
  startSurveyText: {
    default: 'Start',
    es: 'Comienzo'
  },
  pagePrevText: {
    default: 'Back',
    es: 'Espalda'
  },
  pageNextText: {
    default: 'Next',
    es: 'Siguiente'
  },
  completeText: {
    default: 'Submit',
    es: 'Enviar'
  },
  previewText: {
    default: 'Preview',
    es: 'Avance'
  },
  firstPageIsStarted: true,
  showPreviewBeforeComplete: 'showAllQuestions'
};

const suveyModel = new Model(form);
suveyModel.setValue('name', {
  firstname: localStorage.firstname,
  lastname: localStorage.lastname
});
suveyModel.setValue('email', localStorage.email);
suveyModel.setValue('type', localStorage.assessmentType);

export default suveyModel;

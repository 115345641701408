import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { ReportsList } from './ServiceRequestReportsList';
import { AssessmentReportList } from './AssessmentReportList';

export const Reports = (props: RouteComponentProps) => {
  return (
    <BaseAdmin selectedMenu={['reports']}>
      <Route
        exact
        path={`/reports/assessment`}
        component={AssessmentReportList}
      />
      <Route exact path={`/reports/serviceRequest`} component={ReportsList} />
    </BaseAdmin>
  );
};

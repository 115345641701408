import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { BaseAdmin } from '../base-admin/BaseAdmin';
import { TouchpointsList } from './TouchpointsList';
import { ReportsList } from '../reports/ServiceRequestReportsList';
import { EditTouchpoint, CreatTouchpoint } from './TouchpointFormController';

export const Touchpoints = (props: RouteComponentProps) => {
  const {
    match: { path }
  } = props;
  return (
    <BaseAdmin selectedMenu={['touchpoints']}>
      <Route exact path={`${path}/:touchId/reports`} component={ReportsList} />
      <Route exact path={path} component={TouchpointsList} />
      <Route exact path={`${path}/:touchId/edit`} component={EditTouchpoint} />
      <Route exact path={`${path}/create`} component={CreatTouchpoint} />
    </BaseAdmin>
  );
};

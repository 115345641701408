import React, { FC } from 'react';
import { ReactComponent as Loading } from '../../assets/svg/loading.svg';
import { ReactComponent as Check } from '../../assets/svg/check.svg';
import './Submit.scss';

interface SubmitProps {
  title?: string;
  loading?: boolean;
  disable?: boolean;
  check?: boolean;
}

const Submit: FC<SubmitProps> = ({ loading, check, title = 'Submit' }) => {
  return (
    <button className={`submit_comp ${check && 'check_button'}`} type="submit">
      {loading ? (
        <Loading className="loading" />
      ) : check ? (
        <Check className="check" />
      ) : (
        title
      )}
    </button>
  );
};

export default Submit;

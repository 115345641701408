import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { setToken, removeToken } from './auth';

export const tokenKey = 'userAccessToken';
export const tokenHeader = 'Authorization';

export const axiosInterceptors = (axiosIns: AxiosInstance) => ({
  request: {
    ok: (config: AxiosRequestConfig) => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
        config.headers[tokenHeader] = 'Bearer ' + token;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    error: (error: any) => {
      Promise.reject(error);
    }
  },
  response: {
    ok: (response: AxiosResponse) => {
      return response;
    },

    error: (error: any) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
          .get('/api/refreshtoken')
          .then(async res => {
            await setToken(res.data.access_token);
            axiosIns.defaults.headers.common[tokenHeader] =
              'Bearer ' + localStorage.getItem(tokenKey);
          })
          .then(res => axiosIns(originalRequest))
          .catch(err => {
            removeToken();
            window.location.assign('/login');
            return Promise.reject(err);
          });
      }
      return Promise.reject(error);
    }
  }
});

import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Tooltip } from 'antd';
import { Input, Form, Select, Checkbox } from 'formik-antd';
import { Button } from 'antd';
import {
  PlusOutlined,
  DragOutlined,
  DeleteOutlined,
  RightCircleOutlined,
  DoubleRightOutlined,
  RetweetOutlined,
  PlayCircleOutlined,
  FullscreenExitOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  PlusSquareOutlined,
  WarningOutlined,
  FormOutlined,
  CopyOutlined,
  LineChartOutlined,
  GoogleOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
  BulbOutlined,
  BellOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarryOutOutlined,
  CoffeeOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
  EnvironmentOutlined,
  FileOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  LikeOutlined,
  MailOutlined,
  SafetyOutlined,
  SafetyCertificateOutlined,
  PhoneOutlined,
  NotificationOutlined,
  SmileOutlined,
  ToolOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  TagOutlined,
  WifiOutlined,
  UserOutlined,
  ShopOutlined
} from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Typography } from 'antd';
import useButtonsModal from '../../../../components/buttonsModal/buttonsModal';
import { TouchpointButttonType } from '../../../../services/api/Touchpoint/touchpoint.model';

const icons = [
  { value: <RightCircleOutlined />, key: 'RightCircleOutlined' },
  { value: <DoubleRightOutlined />, key: 'DoubleRightOutlined' },
  { value: <RetweetOutlined />, key: 'RetweetOutlined' },
  { value: <PlayCircleOutlined />, key: 'PlayCircleOutlined' },
  { value: <FullscreenExitOutlined />, key: 'FullscreenExitOutlined' },
  { value: <QuestionCircleOutlined />, key: 'QuestionCircleOutlined' },
  { value: <CheckCircleOutlined />, key: 'CheckCircleOutlined' },
  { value: <PlusSquareOutlined />, key: 'PlusSquareOutlined' },
  { value: <WarningOutlined />, key: 'WarningOutlined' },
  { value: <FormOutlined />, key: 'FormOutlined' },
  { value: <CopyOutlined />, key: 'CopyOutlined' },
  { value: <LineChartOutlined />, key: 'LineChartOutlined' },
  { value: <GoogleOutlined />, key: 'GoogleOutlined' },
  { value: <FacebookOutlined />, key: 'FacebookOutlined' },
  { value: <YoutubeOutlined />, key: 'YoutubeOutlined' },
  { value: <TwitterOutlined />, key: 'TwitterOutlined' },
  { value: <InstagramOutlined />, key: 'InstagramOutlined' },
  { value: <BulbOutlined />, key: 'BulbOutlined' },
  { value: <BellOutlined />, key: 'BellOutlined' },
  { value: <CalendarOutlined />, key: 'CalendarOutlined' },
  { value: <CameraOutlined />, key: 'CameraOutlined' },
  { value: <CarryOutOutlined />, key: 'CarryOutOutlined' },
  { value: <CoffeeOutlined />, key: 'CoffeeOutlined' },
  { value: <CommentOutlined />, key: 'CommentOutlined' },
  { value: <CustomerServiceOutlined />, key: 'CustomerServiceOutlined' },
  { value: <EnvironmentOutlined />, key: 'EnvironmentOutlined' },
  { value: <FileOutlined />, key: 'FileOutlined' },
  { value: <FileDoneOutlined />, key: 'FileDoneOutlined' },
  { value: <FileTextOutlined />, key: 'FileTextOutlined' },
  { value: <LikeOutlined />, key: 'LikeOutlined' },
  { value: <MailOutlined />, key: 'MailOutlined' },
  { value: <SafetyOutlined />, key: 'SafetyOutlined' },
  { value: <SafetyCertificateOutlined />, key: 'SafetyCertificateOutlined' },
  { value: <PhoneOutlined />, key: 'PhoneOutlined' },
  { value: <NotificationOutlined />, key: 'NotificationOutlined' },
  { value: <SmileOutlined />, key: 'SmileOutlined' },
  { value: <ToolOutlined />, key: 'ToolOutlined' },
  { value: <ShoppingCartOutlined />, key: 'ShoppingCartOutlined' },
  { value: <ShoppingOutlined />, key: 'ShoppingOutlined' },
  { value: <TagOutlined />, key: 'TagOutlined' },
  { value: <WifiOutlined />, key: 'WifiOutlined' },
  { value: <UserOutlined />, key: 'UserOutlined' },
  { value: <ShopOutlined />, key: 'ShopOutlined' },
  { value: <PlusOutlined />, key: 'PlusOutlined' }
];

const { Title } = Typography;

const selectBefore = (name: string, disable?: boolean) => (
  <Select name={name} style={{ width: 88 }} disabled={disable}>
    <Select.Option value="http://">http://</Select.Option>
    <Select.Option value="https://">https://</Select.Option>
    <Select.Option value="tel:">tel:</Select.Option>
    <Select.Option value="sms:">sms:</Select.Option>
    <Select.Option value="mailto:">mailto:</Select.Option>
    <Select.Option value=""> </Select.Option>
  </Select>
);

const DragHandle = SortableHandle(() => (
  <span>
    <Tooltip title="Arrange">
      <DragOutlined />
    </Tooltip>
  </span>
));

const SortableItem = SortableElement(
  ({
    value,
    i,
    helper
  }: {
    value: TouchpointButttonType;
    i: number;
    helper: FieldArrayRenderProps;
  }) => {
    return (
      <li>
        <Row gutter={24}>
          <Col sm={2}>
            <Form.Item name={`links[${i}].enable`}>
              <Checkbox name={`links[${i}].enable`} />
            </Form.Item>
          </Col>
          <Col sm={5}>
            <Form.Item name={`links[${i}].title`}>
              <Input name={`links[${i}].title`} disabled={value.default} />
            </Form.Item>
          </Col>
          <Col sm={10}>
            <Form.Item name={`links[${i}].url`}>
              <Input
                name={`links[${i}].url`}
                disabled={value.type !== 'custom'}
                addonBefore={selectBefore(
                  `links[${i}].protocol`,
                  value.default
                )}
              />
            </Form.Item>
          </Col>
          <Col sm={2}>
            <Form.Item name={`links[${i}].icon`}>
              <Select name={`links[${i}].icon`} defaultValue="1">
                {icons.map(icon => (
                  <Select.Option
                    value={icon.key}
                    key={icon.key}
                    disabled={value.default}
                  >
                    {icon.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={2}>
            <Row>
              <Col sm={12}>
                {!value.default && (
                  <Tooltip title="Delete">
                    <DeleteOutlined
                      onClick={() => {
                        helper.remove(i);
                      }}
                    />
                  </Tooltip>
                )}
              </Col>
              <Col sm={12}>
                <DragHandle />
              </Col>
            </Row>
          </Col>
        </Row>
      </li>
    );
  }
);

const SortableContainerIns = SortableContainer(
  ({ children }: { children: any }) => {
    return <ul>{children}</ul>;
  }
);

const onSortEnd = (move: (from: number, to: number) => void) => ({
  oldIndex,
  newIndex
}: {
  oldIndex: number;
  newIndex: number;
}) => {
  move(oldIndex, newIndex);
};

export function TouchpointButtonsForm(props: any) {
  const { values } = props;
  const { ButtonModal, oepnModal } = useButtonsModal(values.facilityId);

  return (
    <div>
      <ButtonModal />
      <Row gutter={24} style={{ marginLeft: 30, marginBottom: 20 }}>
        <Col sm={2}>
          <Title level={5}>Enable</Title>
        </Col>
        <Col sm={5}>
          <Title level={5}>Title</Title>
        </Col>
        <Col sm={10}>
          <Title level={5}>URL</Title>
        </Col>
        <Col sm={2}>
          <Title level={5}>Icon</Title>
        </Col>
        <Col sm={2}>
          <Title level={5}>Actions</Title>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={24}>
          <FieldArray
            name="links"
            render={arrayHelpers => (
              <>
                <SortableContainerIns
                  onSortEnd={onSortEnd(arrayHelpers.move)}
                  useDragHandle
                  lockAxis="y"
                  helperClass="dragging-helper-class"
                >
                  {values.links.map((lnk: any, index: number) => (
                    <SortableItem
                      key={`item-${index}`}
                      index={index}
                      value={lnk}
                      i={index}
                      helper={arrayHelpers}
                    />
                  ))}
                  <Button
                    type="dashed"
                    onClick={async () => {
                      oepnModal((x, type) => {
                        const b: TouchpointButttonType = {
                          enable: true,
                          protocol: '',
                          title: '',
                          url:
                            type === 'form'
                              ? (values.token && values.token !== ''
                                  ? values.token
                                  : ':touchpointToken') +
                                '/form' +
                                x
                              : type === 'assessment'
                              ? (values.token && values.token !== ''
                                  ? values.token
                                  : ':touchpointToken') +
                                '/assessment' +
                                x
                              : '',
                          icon:
                            type === 'assessment'
                              ? 'FileTextOutlined'
                              : type === 'form'
                              ? 'FileTextOutlined'
                              : 'BellOutlined',
                          default: false,
                          type
                        };

                        arrayHelpers.push(b);
                      });
                    }}
                    block
                  >
                    <PlusOutlined /> Add field
                  </Button>
                </SortableContainerIns>
              </>
            )}
          />
        </Col>
      </Row>
    </div>
  );
}

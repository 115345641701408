import React, { FunctionComponent } from 'react';
import { Field, FieldAttributes } from 'formik';
import './Input.scss';

interface InputType {
  title?: string;
  name: string;
  placeholder?: string;
  type?: 'text' | 'password' | 'email' | 'texarea';
}

const Input: FunctionComponent<InputType> = ({
  name,
  placeholder,
  type = 'text',
  title
}) => (
  <Field
    name={name}
    render={({ field, form, meta }: FieldAttributes<any>) => (
      <div className="input_comp">
        <label className="input_comp__title" htmlFor={name}>
          {title}
        </label>
        {type === 'texarea' ? (
          <textarea {...field} placeholder={placeholder} />
        ) : (
          <input
            className={meta.touched && meta.error && 'error'}
            type={type}
            {...field}
            placeholder={placeholder}
          />
        )}
        <div className="subfield">
          {meta.touched && meta.error && meta.error}
        </div>
      </div>
    )}
  />
);

export default Input;

import React, { FC } from 'react';
import { format } from 'date-fns';

interface DaterProps {
  date: Date;
  formater?: string;
  inLocal?: boolean;
}

const Dater: FC<DaterProps> = ({
  date,
  formater = 'MM/dd/yyyy hh:mm',
  inLocal = true
}) => {
  return inLocal ? (
    <span>
      {new Date(date).toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })}
    </span>
  ) : (
    <span>{format(date, formater)}</span>
  );
};

export default Dater;

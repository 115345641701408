import React from 'react';
import { Row, Col } from 'antd';
import { EmailListInput } from '../../../../components/EmailListInput';

export function FacilityEmailInformationForm(props: any) {
  const { values } = props;
  return (
    <div>
      <Row gutter={24}>
        <Col sm={12} xs={24}>
          <EmailListInput
            name="ccEmails"
            emails={values.ccEmails}
            label="ccEmails"
          />
        </Col>
        <Col sm={12} xs={24}>
          <EmailListInput
            name="bccEmails"
            emails={values.bccEmails}
            label="bccEmails"
          />
        </Col>
      </Row>
    </div>
  );
}
